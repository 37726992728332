// Org Login
export const FETCH_ORGANISATION_DETAILS = '/dashboard/orgdetails';
export const LOGIN = '/dashboard/login';
export const FORGOT_PASSWORD = '/dashboard/forgotpassword';
export const VALIDATE_OTP = '/dashboard/validateotp';
export const UPDATE_EMPLOYEE_PASSWORD = '/dashboard/changepasswordOTP';
export const GET_AVAILABLE_SSO_FOR_LOGIN = '/Dashboard/login/sso/v1/get';
export const GET_OIDC_OAUTH_URL = '/Dashboard/login/oidc/v1/getOAuthURL';
export const SSO_OIDC_CALLBACK_URL = '/external/sso/callback/oidc';

// Org Logout
export const LOGOUT = '/dashboard/logout';

// Dashboard
export const GET_MASTER_DATA = '/CRMDashboard/masterdata/fetch';
export const GET_ALL_CONSIGNMENT_DATA = '/CRMDashboard/consignments/fetch';
export const GET_CONSIGNMENT_EXTRA_DATA = '/CRMDashboard/consignments/fetchExtraData';
export const GET_QUERY_BUILDER_REPORT = '/CRMDashboard/queryBuilderReportVisualisation';
export const GET_ALL_PLANNING_DATA = '/Dashboard/planning/v1/get';
export const GET_ALL_PICKUP_DETAILS = '/Dashboard/business/pickup/v1/getDetails';
export const ADD_PICKUP_REQUEST = '/retaildashboard/tripdetails/bulk/task/add';
export const ADD_PICKUP_TASK = '/retaildashboard/trip/bulk/tasks/add';
export const DELETE_PICKUP_REQUEST = '/retaildashboard/tripdetails/bulk/task/delete';
export const GET_TASK_TYPE = '/RetailDashboard/getTaskDetails';
export const SEARCH_HUB = '/CRMDashboard/search/hubs';
export const SEARCH_HUB_WITH_REAL_FILTER = '/Dashboard/consignments/searchEmployeeHubs';
export const SEARCH_CUSTOMERS = '/CRMDashboard/search/customers';
export const SEARCH_SHELF_NUMBER = '/CRMDashboard/search/shelf';
export const SEARCH_FILTERED_HUBS = '/Dashboard/customeractivity/getfilteredhubs';
export const FETCH_ONE = '/CRMDashboard/consignments/fetchOne?referenceNumber';
export const DECRYPT_CN = '/CRMDashboard/consignment/piidata/decrypt';
export const DOWNLOAD_RIDER_EVENTS = '/CRMDashboard/consignments/downloadRiderEvents';
export const MARK_RTO = '/CRMDashboard/consignments/markConsignmentAsRto';
export const MARK_REVOKE_RTO = '/CRMDashboard/consignments/markConsignmentAsRevokeRto';
export const MARK_SHIPMENT_DESTROYED = '/CRMDashboard/consignment/v1/markConsignmentAsShipmentDestroyed';
export const MARK_PICKUP_COMPLETED = '/CRMDashboard/consignment/v1/markConsignmentAsPickupCompleted';
export const MARK_NOT_PICKEDUP = '/CRMDashboard/consignment/v1/markConsignmentAsNotPickedUp';
export const MARK_RTO_CONFIRMED = '/CRMDashboard/consignment/v1/markConsignmentAsRtoConfirmed';
export const LOST_CONSIGNMENT = '/Dashboard/consignmentLost';
export const REVOKE_CONSIGNMENT = '/CRMDashboard/consignments/undoDelivered';
export const SET_ONHOLD = '/Dashboard/Tasks/onHold';
export const MARK_CN_ELIGIBLE_FOR_LM_PLANNING = '/Dashboard/Tasks/markCnEligibleForLastMilePlanning';
export const RELEASE_ONHOLD = '/Dashboard/Tasks/onHoldRelease';
export const RESCHEDULE_DELIVERY = '/CRMDashboard/consignments/deliveryReschedule';
export const RESCHEDULE_PICKUP = '/CRMDashboard/consignments/pickupReschedule';
export const UPDATE_DUTY_CHARGES_BULK = '/CRMDashboard/consignments/editDutyCharges';
export const UPDATE_BULK_COD = '/CRMDashboard/consignments/updateCodAmount';
export const UPDATE_BULK_RESCHEDULE = '/CRMDashboard/consignments/bulkDeliveryReschedule';
export const UPDATE_USER_COLUMNS = '/CRMDashboard/update/columns';
export const UPDATE_USER_COLUMNS_OPS_V2 = '/Dashboard/update/opsV2Consignmentcolumns';
export const REQUEST_DOWNLOAD = '/CRMDashboard/consignments/download';
export const GET_DOWNLOADS = '/CRMDashboard/consignments/downloadRequests';
export const EDIT_CONSIGNMENT = '/CRMDashboard/consignments/editConsignment/';
export const ADD_CONSIGNMENT = '/consignment/ops/save/singleConsignment';
export const SEARCH_ACCOUNTS = '/Dashboard/Tasks/searchCustomerPickup?search=';
export const GET_CONTENT_TYPE = '/consignment/getContentType';
export const GET_DELIVERY_LOCATION = '/CRMDashboard/consignments/getDeliveryLocation/';
export const SET_DELIVERY_LOCATION = '/CRMDashboard/consignments/setDeliveryLocation/';
export const FETCH_ADDRESS_NODE_DATA = '/Dashboard/addressManagement/getAddressNodeData';
export const FETCH_ADDRESS_META_DATA = '/Dashboard/addressManagement/getAddressMetaData';
export const FETCH_ADDRESS_HIERARCHY_DATA = '/Dashboard/addressManagement/getHierarchyData';
export const MARK_UNDELIVERED_REASON = '/CRMDashboard/consignments/markConsignmentAsUndelivered/v2';
export const EDIT_NOTES_CRM_DASHBOARD = '/CRMDashboard/consignments/setConsignmentNotes';
export const FETCH_CRM_SUMMARY_METRICS_DATA = '/Dashboard/summaryMetricsApi/getSummaryMetricsCount';
export const FETCH_CONSOLIDATED_SUMMARY_METRICS_DATA = '/Dashboard/planning/v1/getSummaryMetricsCount';
export const FETCH_CONSOLIDATED_SUBBUCKET_SUMMARY_METRICS_DATA = '/Dashboard/planning/v1/getSubBucketSummaryMetrics';
export const INSCAN_AT_HUB = '/Dashboard/Tasks/markConsignmentAsInscanAtHub';
export const ARRIVED_IN_COUNTRY = '/Dashboard/Tasks/markConsignmentAsArrivedInCountry';
export const ARRIVED_AT_RETURN_HUB = '/Dashboard/Tasks/markConsignmentAsArrivedAtReturnHub';
export const PICKED_UP_INSCAN_AT_HUB = '/Dashboard/consignment/pickedUp/markInscanAtHub';
export const OUTSCAN_AT_HUB = '/Dashboard/Tasks/outscanAtHub';
export const EMAIL_CARRIER = '/ltl/email/create';
export const ASSIGN_SHIPPERS_NEXT_STEPS = '/Dashboard/editOpsV2ShipperNextStepsMultiple';
export const ASSIGN_TAGS = '/Dashboard/editOpsV2TagsMultiple';
export const ASSIGN_REMARKS_CARRIER = '/Dashboard/editOpsV2RemarksMultiple';
export const DELETE_SHIPMENT = '/ltl/track/shipments/delete';
export const FETCH_SUB_BUCKET_DATA = '/Dashboard/summaryMetricsApi/getSubBucketSummaryMetrics';
export const BULK_CN_UPLOAD = '/Dashboard/consignments/csvUploadForAction';
export const FETCH_OPTIMISER_TAGS = '/Dashboard/setup/OptimizerTag/v1/fetch'
export const RESCHEDULE_PICKUPS = '/CRMDashboard/consignments/editLatestPickupTimeslot';
export const CONSIGNMENT_QUALITY_CHECK = '/Dashboard/consignment/consignmentVerification/fetch';
export const CONSIGNMENT_QUALITY_CHECK_UPDATE = '/Dashboard/consignment/consignmentVerification/update';
export const MARK_CANCEL = '/Dashboard/coordinator/pickup/cancelReverseConsignments';
export const REVOKE_CANCEL = '/CRMDashboard/cancelRevoke';
export const MARK_CONSIGNMENT_AS_CANCELLED = '/CRMDashboard/consignments/markConsignmentAsCancelled';
export const GET_CANCELLATION_REASONS = '/Dashboard/coordinator/pickup/getReverseCancelReasons';
export const BULK_CN_UPLOAD_WITH_PIECES = '/Dashboard/consignments/softdata/excelUpload';
export const BULK_CN_UPDATE_WITH_PIECES = '/Dashboard/consignments/softdata/excelUpdate';
export const GENERIC_DASHBOARD_FETCH_DOWNLOAD_REQUESTS_V1 = '/Dashboard/download/v1/getRequests';
export const GENERATE_REPORT_DASHBOARD = '/Dashboard/reports/v1/download';
export const DOWNLOAD_SAMPLE_CSV_FOR_CNV = '/Dashboard/consignment/consignmentVerification/sample_csv_cnv.csv';
export const DOWNLOAD_SAMPLE_CSV_FOR_CONSIGNMENT = '/Dashboard/consignment/upload/softdata/excel';
export const DOWNLOAD_SAMPLE_CSV_FOR_UPDATE_CONSIGNMENT = '/Dashboard/consignment/update/softdata/excel';
export const BULK_CN_UPLOAD_FOR_CNV = '/Dashboard/consignment/consignmentVerification/update/upload';
export const DOWNLOAD_SAMPLE_CSV_TO_UPDATE_CNS = '/CRMdashboard/consignments/sample_csv_action_update.csv';
export const DOWNLOAD_SAMPLE_EXCEL_TO_UPDATE_CNS = '/CRMdashboard/consignments/sample_excel_action_update';
export const UPDATE_PAYMENT_CLEARED_FOR_CNS = '/CRMdashboard/consignments/updatePaymentStatus';
export const MARK_CN_INSCAN_AT_HUB_BULK = '/CRMDashboard/consignments/markConsignmentAsInscanAtHub';
export const MARK_CN_ARRIVAL_AT_HUB_BULK = '/CRMDashboard/consignments/markConsignmentAsArrivedAtHub';
export const REVERT_PICKUP_FAILED = '/CRMDashboard/consignments/undoPickupFailed';
export const REVERT_PICKUP_COMPLETED = '/CRMDashboard/consignments/undoPickupCompleted';
export const REFRESH_ROUTE_NEXT_HUB = '/CRMDashboard/consignments/refreshNextHub';
export const REFRESH_EWAYBILL_DETAILS = '/CRMDashboard/consignments/refreshEwayBillDetails';
export const REFRESH_DESTINATION_AND_HOLDING_HUB = '/CRMDashboard/consignments/refreshHoldingAndDestinationHub';
export const MARK_PICKUP_AWAITED = '/CRMDashboard/consignments/markPickupAwaited';
export const REFRESH_ORIGIN_HUB = '/CRMDashboard/consignments/refreshOriginHub';
export const UPDATE_ADDRESS_BULK = '/CRMDashboard/consignments/editAddress';
export const FETCH_CALL_LOGS = '/CRMDashboard/consignments/callLogs/fetch';
export const UPDATE_CALL_LOGS = '/CRMDashboard/consignments/callLogs/add';
export const UNASSIGN_CARRIERS_THIRD_PARTY = '/CRMDashboard/unassignCourierPartner';
export const DOWNLOAD_DISPATCH_SUMMARY = '/CRMDashboard/downloadDispatchSummary';
export const USER_CONSENT = '/Dashboard/recordDataConsent';
export const CREATE_SEPARATE_TRIP_MANAGER_DOWNLOADS_REQUESTS = '/CRMDashboard/trips/v3/download';
export const END_INVENTORY_SCAN = '/Dashboard/hub/v1/endInventoryScan';
export const START_INVENTORY_SCAN ='/Dashboard/hub/v1/startInventoryScan';
export const BULK_SET_TRANSPORTER_IDS = '/CRMDashboard/consignments/updateEwayBillTransporter';
export const GET_IMAGE_URL = '/Dashboard/consignment/generateTaskImageUrl';
export const REQUEST_REPORTS_DOWNLOAD = '/CRMDashboard/consignments/reports/v1/download';
export const GET_APPROVAL_REQUESTS = '/CRMDashboard/request/get';
export const CREATE_ADDRESS_REQUESTS = '/CRMDashboard/request/create';
export const REJECT_REQUESTS = '/CRMDashboard/request/reject';
export const APPROVE_REQUESTS = '/CRMDashboard/request/approve';
export const GET_APPROVAL_METRICS = '/CRMDashboard/request/getBucketMetricCounts';
export const VERIFY_NATIONAL_DESTINATION_CODE = '/CRMDashboard/consignments/getAddressDetailsFromNationalAddressCode';
export const PRINT_MANIFEST_DRS = '/Dashboard/Consignments/printDRS';
export const PRINT_POD = '/Dashboard/Consignments/printPOD';
export const CALL_CENTER_METRICS = '/CRMDashboard/consignments/callCenter/getMetrics';
export const AWB_NO_STATUS = '/CRMDashboard/consignments/callCenter/referenceNumberStatus';
export const ASSIGN_OR_UNASSIGN_EMP = '/CRMDashboard/consignments/callCenter/assignOrUnassignToEmployee';
export const UNASSIGN_ALL_CALLS_EMPLOYEE = '/CRMDashboard/consignments/callCenter/unassignAllCallsEmployee';
export const DOWNLOAD_ASSIGN_AWB_SAMPLE_CSV = '/CRMDashboard/consignments/callCenter/sampleAssignConsignmentExcel.csv';
export const GET_CALLS_OVERVIEW_DATA = '/CRMDashboard/consignments/callCenter/getSummary';
export const SET_NOTES = '/CRMDashboard/consignments/callCenter/setNotes';
export const FETCH_NOTES = '/CRMDashboard/consignments/callCenter/getNotes';
export const UPDATE_PHONE_NUMBER = '/CRMDashboard/consignments/callCenter/phoneUpdate';
export const GET_EMPLOYEE_HUB = '/Dashboard/consignments/getEmployeeHub';
export const QWIK_SETTLEMENT_LOGS = '/Dashboard/erpsynclogs/v1/qwikSettlementLogs';
export const PIS_SAP_INTEGRATION_LOGS = '/Dashboard/erpsynclogs/v1/payInSlipIntegrations';
export const RETRY_INTEGRATION = '/Dashboard/erpsynclogs/v1/retryIntegration';
export const CUSTOMER_WEBHOOK_LOGS = '/Dashboard/erpsynclogs/getCustomerWebhookLogs';
export const RETRY_CUSTOMER_WEBHOOK_INTEGRATION = '/Dashboard/erpsynclogs/v1/retryCustomerWebhookIntegration';
export const GET_PLANNING_DATA = '/Dashboard/planning/v1/get';
export const ASSIGN_TO_PREVIOUS_TRIP = '/retaildashboard/tripdetails/addToPreviousTrip';
export const APPLY_EXCEPTION_ON_CONSIGNMENT = '/Dashboard/consignments/v1/applyException';
export const RELEASE_EXCEPTION_ON_CONSIGNMENT = '/Dashboard/consignments/v1/releaseException';
export const APPLY_PIECE_LEVEL_EXCEPTION_ON_CONSIGNMENT = '/Dashboard/consignments/v1/applyPieceLevelExceptions';
export const RELEASE_PIECE_LEVEL_EXCEPTION_ON_CONSIGNMENT = '/Dashboard/consignments/v1/releasePieceLevelExceptions';
export const FETCH_AND_VALIDATE_CONSIGNMENT = '/CRMDashboard/consignment/v1/fetchAndValidate';
export const MARK_CONSIGNMENT_EXCEPTION = '/CRMDashboard/consignments/v1/markExceptionOnConsignment';
export const MARK_CN_INTRANSIT_TO_HUB_BULK = '/CRMDashboard/consignments/markConsignmentAsInTransitToHub';
export const GET_SERVICE_TYPES = '/serviceTypes/ops/get';
export const GET_ALL_AVAILABLE_AND_BOOKED_SLOTS= '/CRMDashboard/addressManagement/getAllAvailableAndBookedSlots';

export const CN_UPLOAD_REQUESTS = '/RetailDashboard/asyncjob/consignment/upload_consignments.csv';
export const BULK_UPDATE_CONSIGNMENT_DETAILS = '/CRMDashboard/consignments/v1/updateConsignmentDetailsBulk';
export const BULK_UPDATE_CONSIGNMENT_EVENT_NOTES = '/CRMDashboard/consignments/v1/updateConsignmentEventNotes';
export const ADD_COMMENT = '/CRMDashboard/pieces/addComments';
export const GET_PICKUP_SLOTS = '/businessPickupSlots/ops/get';
export const GET_ALL_ZONES = (hubId) => `/CRMDashboard/hub/${hubId}/getAllZones`;
export const MARK_CONSIGNMENT_AS_FREE_OF_COST = '/CRMDashboard/consignments/v1/markConsignmentAsFreeOfCost';
export const MARK_CONSIGNMENT_AS_OUT_FOR_DELIVERY = '/CRMDashboard/consignments/markConsignmentAsOutForDelivery';
export const MARK_CONSIGNMENT_AS_OUT_FOR_PICKUP = '/CRMDashboard/consignments/markConsignmentAsOutForPickup';
export const UPDATE_CONSIGNMENT_DESCRIPTION = '/CRMDashboard/consignments/v1/editConsignmentDescription';
export const VALIDATE_INSCAN_CN = '/RetailDashboard/consignments/validateInScannedConsignments';
export const BULK_TRIGGER_INTEGRATION = '/CRMDashboard/consignment/integration/v1/trigger';
export const BULK_COURIER_PARTNER_REFERENCE_NUMBER_CHANGE_MANUAL = '/CRMDashboard/consignment/v1/changeCourierPartnerReferenceNumberManual';
export const REMOVE_HU = '/CRMDashboard/consignment/v1/removeHu';
export const UPDATE_EWB = '/CRMDashboard/consignments/updateEWB';
export const AUTHENTICATE_ACTIVATION_CODE = '/Dashboard/authenticateActivationCode';
export const BULK_UPDATE_POD = '/CRMDashboard/consignment/v1/UpdatePodMultiple';
export const UPDATE_POD_SINGLE = '/CRMDashboard/consignment/v1/UpdatePodList';
export const GET_POD_SINGLE = '/CRMDashboard/consignment/v1/getPodList';
export const GET_MULTIPLE_S3_IMAGE_URL = '/CRMDashboard/consignment/v1/generateMultipleS3ImageUrl';
export const SAVE_ADDRESS = '/businessAddress/ops/save';
export const EDIT_ADDRESS = '/businessAddress/ops/edit';
export const FCM_TOKEN_UPDATE = '/Dashboard/fcm/updatetoken';
export const GET_NOTIFICATION_AUTH_TOKEN = '/CRMDashboard/notification/getAuthToken';
export const UPDATE_USER_PREFERENCE = '/CRMDashboard/userPreference';
export const UPDATE_TASK_STATUS = '/CRMDashboard/updateJobTaskStatus';

// Pay in Slip
export const FETCH_PIS_SUMMARY = '/Dashboard/pis/summary/v1/fetch';
export const CREATE_PIS = '/Dashboard/pis/v1/create';
export const FETCH_PIS = '/Dashboard/pis/v1/fetch';
export const UPDATE_PIS = '/Dashboard/pis/v1/update';
export const UPLOAD_PIS = '/Dashboard/pis/v1/upload';
export const DOWNLOAD_PIS = '/Dashboard/pis/v1/download';
export const GET_PIS_S3_URL = '/Dashboard/pis/v1/getS3Url';
export const DELETE_PIS = '/Dashboard/pis/v1/delete';
export const FETCH_ZONES = '/Dashboard/WorkerManagement/tasks/getAddressNodesForWorkerAllocation';

//Vehicle Request
export const FETCH_VEHICLE_REQUESTS = '/TmsDashboard/vehicle/request/fetch';
export const UPDATE_VEHICLE_REQUEST = '/TmsDashboard/vehicle/request/update';
export const CREATE_VEHICLE_REQUEST = '/TmsDashboard/vehicle/request/create';
export const HANDLE_ACTION_CHANGE_VEHICLE_REQUEST = '/TmsDashboard/vehicle/request/action';
// Retail
export const GET_VEHICLE_MAKE = '/Dashboard/vehiclemake/get';
export const GET_VEHICLE_MAKES_BY_HUB_ID = '/Dashboard/hubvehiclemake/get';
export const GET_ALL_RETAIL_CONSIGNMENT_DATA = '/retaildashboard/consignments/get';
export const GET_ALL_TRIPS_DATA = '/retaildashboard/tripmanager/get';
export const CREATE_ROUTE = '/retaildashboard/consignments/multistage/createroute';
export const CREATE_ROUTE_ASYNC = '/retaildashboard/consignments/multistage/createrouteasync';
export const CREATE_ROUTE_ALL = '/retaildashboard/consignments/multistage/createrouteall';
export const CREATE_ROUTE_ALL_ASYNC =
 '/retaildashboard/consignments/multistage/createrouteallasync';
export const ASSIGN_RIDER = '/retaildashboard/tripmanager/assigntoworker';
export const ASSIGN_VEHICLE = '/retaildashboard/tripmanager/assigntovehicle';
export const GET_FREE_WORKERS = '/retaildashboard/tripmanager/getworkers';
export const GET_AVAILABLE_WORKERS = '/Dashboard/getShiftDetails';
export const GET_FREE_VEHICLES = '/retaildashboard/tripmanager/getVehicles';
export const GET_FREE_HELPER_WORKERS = '/retaildashboard/tripmanager/gethelpers';
export const ASSIGN_HELPER = '/retaildashboard/tripmanager/assigntohelper';
export const GET_TRIPS = '/retaildashboard/tripdetails/gettrips';
export const GET_OPTIMIZER_REQUESTS = '/retaildashboard/consignments/requests/get';
export const RETRY_OPTIMIZER_REQUESTS = '/retaildashboard/consignments/requests';
export const FETCH_ONE_TRIP = '/retaildashboard/tripdetails/get?';
export const DELETE_CN = '/retaildashboard/tripdetails/delete';
export const ADD_CN = '/retaildashboard/tripdetails/add';
export const VERIFY_MERCHANT_HOURS = '/retaildashboard/consignments/validateMerchantHours';
export const BULK_DELETE_TRIPS = '/retaildashboard/action/bulk/deletetrip';
export const BULK_MANUAL_TRIP = '/retaildashboard/action/bulkmanualtrip';
export const BULK_DELETE_TRIP_CONSIGNMENT = '/retaildashboard/tripdetails/bulk/delete';
export const DOWNLOAD_RETAIL_TRIP_DATA = '/retaildashboard/tripmanager/downloadTrips';
export const DOWNLOAD_RETAIL_TRIP_CN_DATA = '/retaildashboard/tripmanager/downloadConsignments';
export const DOWNLOAD_SAMPLE_CSV_RETAIL = '/retaildashboard/consignments/createroute/samplecsv';
export const DOWNLOAD_SAMPLE_MANUAL_CSV = '/retaildashboard/sample_manual_trip';
export const MOVE_CN = '/retaildashboard/tripdetails/reassign';
export const REORDER_CN = '/retaildashboard/tripdetails/rearrange';
export const GET_TRACKING_DATA = '/retaildashboard/tracking/get';
export const GET_PLANNED_TRIP_TRACKING_DATA = '/retaildashboard/tracking/getplanned';
export const REORDER_SEQUENCE = '/retaildashboard/trip/sequence/reorder';
export const DELETE_SEQUENCE = '/retaildashboard/trip/sequence/delete';
export const REORDER_TASK = '/retaildashboard/tripdetails/bulk/task/reorder';
export const UPDATE_CUSTOM_TRIP_CHARGES = '/retaildashboard/tripmanager/updatetripcharges';
export const CREATE_CUSTOM_TRIP_PENALTIES_FLM = '/retaildashboard/tripmanager/v1/addPenalty';
export const CREATE_CUSTOM_TRIP_PENALTIES_FLM_BULK = '/retaildashboard/tripmanager/v1/addPenaltyBulk';
export const CREATE_CUSTOM_TRIP_PENALTIES_IST = '/TMSDashboard/ist/v1/addTripPenalty';
export const GET_SAMPLE_EXCEL_ADD_TRIP_PENALTIES = '/retaildashboard/tripManager/v1/addPenalty/sampleExcel';
export const UPDATE_TRIP_DISTANCE = '/retaildashboard/tripmanager/v1/updateTripChargeableDistance';
export const UPDATE_CONSOLIDATED_PLANNING_COLUMNS = '/Dashboard/update/updateOpsV2ConsolidatedPlanningColumns';
export const UPDATE_CONSOLIDATED_PLANNING_FILTERS = '/Dashboard/update/consolidatedV2Filter';
export const REQUEST_MODEL_CONSOLIDATED_PLANNING = '/Dashboard/planning/downloadConsolidatedBucketTasks';
export const GET_VEHICLE_MAKE_LIST = '/retaildashboard/tripmanager/getvehiclemake';
export const TRIP_UPDATE_VEHICLE_MAKE = '/retaildashboard/tripmanager/vehiclemake/update';
export const GET_VEHICLE_VENDOR_LIST = '/retaildashboard/tripmanager/getvehiclevendor';
export const RESEND_RIDER_CONSENT = '/retaildashboard/tripmanager/resendriderconsent';
// export const GET_TRACKING_HISTORY_DATA = '/retaildashboard/tracking/history';
export const GET_TRACKING_HISTORY_TRIP_LIST = '/retaildashboard/tracking/gettripdata';
export const DOWNLOAD_TRIP_LOGS_TRACKING_DATA = '/retaildashboard/tracking/downloadTripData';
export const GET_TRACKING_HISTORY_DATA = '/retaildashboard/tracking/gethistory';
export const GET_PLANNING_IDS = '/retaildashboard/getPlanningID';
export const GET_ALL_WORKERS = '/retaildashboard/tracking/getallworkers';
export const GET_TRACKING_DIRECTIONS = '/retaildashboard/tracking/getDirections';
export const GET_TRACKING_DIRECTIONS_BY_TASKS = '/retaildashboard/tracking/getDirectionsByTasks';
export const GET_TRIP_SIMULATED_METRICS = '/retaildashboard/tripdetails/calculateSimulatedMetrics';
export const START_TRIP = '/retaildashboard/action/starttrip';
export const END_TRIP = '/retaildashboard/action/endtrip';
export const DELETE_TRIP = '/retaildashboard/action/deletetrip';
export const FREEZE_TRIP = '/retaildashboard/tripdetails/freeze';
export const OPTIMISE_ROUTE = '/retaildashboard/action/optimiseRoute';
export const OPTIMISE_TRIP_ROUTE = '/retaildashboard/trip/optimise';
export const UNFREEZE_TRIP = '/retaildashboard/action/unfreezeTrip';
export const TRIP_SUMMARY_METRICS_DATA = '/retaildashboard/tripmanager/getTripsCount';
export const GET_RETAIL_OVERVIEW_DASHBOARD_LINK = '/retaildashboard/getRetailOverviewDashboardUrl';
export const BULK_ASSIGN_TRIPS = '/retaildashboard/tripmanager/autoAssignTripToWorker';
export const GET_RETAIL_EXCEPTION_METRICS_DASHBOARD_LINK = '/retaildashboard/getMetabaseExceptionDashboardUrl';
export const GET_RETAIL_METABASE_DASHBOARD_LINK = '/retaildashboard/getRetailMetabaseDashboardUrl';
export const UPDATE_USER_TRIP_COLUMNS_OPS_V2 = '/Dashboard/update/opsV2TripColumns';
export const SCHEDULE_RIDER = '/RetailDashboard/tripmanager/scheduletoworker';
export const SCHEDULE_VEHICLE = '/RetailDashboard/tripmanager/scheduletovehicle';
export const VALIDATE_CONSIGNMENTS_FLM = '/RetailDashboard/trip/v1/validateConsignments.csv';
export const BULK_CN_LABEL_PRINT = '/RetailDashboard/consignments/bulklabel/createrequestasync';
export const GET_BULK_LABEL_PRINT_REQUESTS = '/RetailDashboard/consignments/bulklabel/fetchall';
export const CREATE_HAND_DELIVERY = '/retaildashboard/action/createHandDeliverytrip';
export const CHECK_FOR_RESCHEDULED_CNs = '/retaildashboard/tripdetails/validaterescheduledate';
export const CREATE_SECONDARY_TRIPS = '/retaildashboard/consignments/createSecondaryTrips';
export const UPDATE_TRIP_DETAILS = '/RetailDashboard/action/trip/v1/update';
export const BULK_UPDATE_TRIP_DETAILS = '/RetailDashboard/action/trip/v1/bulkUpdate';
export const DOWNLOAD_FLM_TRIP_VERIFICATION_DETAILS = '/RetailDashboard/trip/downloadVerificationDetails';
export const CREATE_MANUAL_TRIP = '/retaildashboard/action/createmanualtrip';
export const GET_CUSTOM_TRIP_REQUESTS = '/retaildashboard/asyncjob/v1/getrequests';
export const MARK_CN_ELIGIBLE_FOR_PLANNING = '/retaildashboard/consignments/markEligibleForPlanning';
export const UPDATE_USER_FILTERS = '/Dashboard/update/opsV2Filter';
export const ZONE_SEARCH = '/Dashboard/WorkerManagement/tasks/getAddressNodesForWorkerAllocation';
export const DOWNLOAD_CALL_LOGS = '/CRMDashboard/consignments/callLogs/download';
export const UPDATE_USER_ACTIONS = '/Dashboard/update/updateOpsV2Action';
export const MARK_ARRIVAL = '/Dashboard/Tasks/sendToCommonPool';
export const MARK_DELIVERED = '/Dashboard/markConsignmentAsDelivered';
export const MARK_CONSIGNMENT_AS_RTO = '/Dashboard/markConsignmentAsRto';
export const PICKUP_AND_DELIVERY = '/Dashboard/markConsignmentAsHyperlocal';
export const REVOKE_PICKUP_AND_DELIVERY = '/Dashboard/revokeConsignmentAsHyperlocal';
export const CONSIGNMENT_STATUS_UPDATE = '/Dashboard/consignments/genericStatusUpdate';
export const GET_REVERSE_NPR_REASONS = '/Dashboard/Tasks/getReverseNprReasons';
export const MARK_NPR = '/Dashboard/Tasks/markConsignmentsNpr';
export const MARK_VEHICLE_BREAKDOWN = '/RetailDashboard/action/vehicleBreakdown';
export const UPDATE_USER_UPDATES = '/Dashboard/update/updateOpsV2Keys';
export const MARK_CONSIGNMENT_AS_PICKUP_COMPLETED = '/Dashboard/markConsignmentAsPickupCompleted';
export const UPDATE_ZONE = '/RetailDashboard/task/ZoneUpdate';
export const DELETE_PICKUP_TASK = '/RetailDashboard/tripdetails/bulk/task/delete';
export const FETCH_ZONE_TASK_RIDER_COUNT = '/RetailDashboard/zonebasedtaskridercount/fetch';
export const GET_WORKER_LOCATION = '/Dashboard/ondemand/worker_location';
export const GET_TRIP_DETAILS_DEFAULT_METADATA = '/retaildashboard/tripdetailsmetadata/getdefault';
export const UPDATE_TRIP_DETAILS_DEFAULT_METADATA = '/retaildashboard/tripdetailsmetadata/update';
export const FETCH_TRIP_CONSIGNMENT_EXCEPTIONS = '/RetailDashboard/action/trip/v1/fetchTripConsignmentExceptions';
export const FETCH_FLESPI_DATA_FOR_TRIP = '/RetailDashboard/tripdetails/iot';
export const ASSIGN_TASK_TO_WORKER = '/RetailDashboard/assignTaskToWorker';

// EFS
export const EFS_TO_PUSH_POD = '/CRMDashboard/efs/trigger/integrations';

//Supplementory Services
export const FETCH_SUPPLEMENTORY_SERVICES = '/Dashboard/supplementary-service-request/v1/fetch';
export const FETCH_SUPPLEMENTORY_SERVICES_LIST = '/Dashboard/supplementary-service-master/v1/fetch';
export const CREATE_SUPPLEMENTORY_SERVICES = '/Dashboard/supplementary-service-request/v1/create';
export const CHANGE_SUPPLEMENTORY_DERVICES_STATUS = '/Dashboard/supplementary-service-request/v1/update';

// Bagging
export const FETCH_BAG_MASTER_DATA = '/Dashboard/bag/getMasterData';
export const GET_AVAILABLE_CONSIGNMENT_FOR_BAGGING =
  '/Dashboard/bag/availableConsignmentForBagging';
export const CREATE_BAG = '/Dashboard/bag/create';
export const GET_ALL_BAGS = '/Dashboard/bag/getAllBags';
export const FETCH_BAG_DETAILS = '/Dashboard/bag/getBagDetails';
export const GET_AVAILABLE_CONSIGNMENT_FOR_DEBAG = '/Dashboard/bag/getBagDetailsForDebag';
export const DE_BAG = '/Dashboard/deBag';
export const BAG_INSCAN_AT_HUB = '/Dashboard/bag/v2/inscan';
export const BAG_OUTSCAN_AT_HUB = '/Dashboard/bag/v2/outscan';
export const SCAN_ITEM_FOR_BAGGING = '/Dashboard/bag/v1/scanItemForBagging';
export const REMOVE_ITEM_FROM_BAGGING_DRAFT = '/Dashboard/bag/v1/removeItemFromBaggingDraft';
export const SCAN_ITEM_FOR_DEBAGGING = '/Dashboard/bag/v1/scanItemForDebagging';
export const REMOVE_ITEM_FROM_DEBAGGING_DRAFT = '/Dashboard/bag/v1/removeItemFromDeBaggingDraft';
export const DOWNLOAD_BAG_REPORT = '/Dashboard/bag/v1/download';
export const UPDATE_BAG_WEIGHTS = '/Dashboard/bag/v1/setBagTransferOrCarrierWeight';
export const VALIDATE_BAG_CONSIGNMENTS = '/Dashboard/bag/v1/validateConsignments';
export const UPDATE_BAG = '/Dashboard/bag/v1/update';
export const FETCH_BAG_VIRTUAL_SERIES = '/Dashboard/bag/virtualSeries/v1/fetch';
export const BAG_DELETE_AT_HUB = '/Dashboard/bag/v1/delete';

// ltl
export const CARRIER_MANAGEMENT_ADD_CARRIER_PARTNER =
  '/ltl/courierPartnerManagement/courierPartner/add';
export const CARRIER_MANAGEMENT_FETCH_CARRIER_PARTNERS =
  '/ltl/courierPartnerManagement/courierPartner/fetch';
export const CARRIER_MANAGEMENT_UPDATE_STATUS =
  '/ltl/courierPartnerManagement/courierPartner/updateStatus';
export const CARRIER_MANAGEMENT_FETCH_SERVICE_TYPES =
  '/ltl/serviceTypes/fetch';
export const CARRIER_MANAGEMENT_ADD_SERVICE_TYPE =
  '/ltl/serviceTypes/add';
export const CARRIER_MANAGEMENT_FETCH_VIRTUAL_SERIES =
  '/series/getSeries';
export const CARRIER_MANAGEMENT_ADD_VIRTUAL_SERIES =
  '/series/addSeries';
export const DOWNLOAD_LTL_TRACKING = '/ltl/track/download/create';
export const GET_DOWNLOAD_REQUESTS = '/ltl/track/download/get ';
export const FETCH_LTL_TRACKING = '/ltl/track/shipments/fetch';
export const CREATE_LTL_TRACKING = '/ltl/track/shipments/create';
export const EDIT_LTL_SHIPMENT = '/ltl/track/shipments/edit';
export const DELETE_LTL_SAVED_ADDRESS = '/ltl/address/delete';
export const EDIT_LTL_ADDRESS = '/ltl/address/edit';
export const CREATE_LTL_ADDRESS = '/ltl/address/create';
export const FETCH_LTL_ADDRESS = '/ltl/address/fetch';
export const FETCH_LTL_DETAILS = '/Dashboard/getShipmentDetail';
export const POLL_LTL_TRACKING = '/ltl/track/shipments/status/fetch';
export const DELETE_LTL = '';
export const FETCH_LTL_MULTIPLE_UPLOAD = '/ltl/track/shipments/createMultiple';
export const LTL_BOOK_MULTIPLE_SHIPMENTS = '/ltl/track/shipments/bookMultiple';
export const SEARCH_CUSTOMER_LTL = '/ltl/track/searchCustomer';
export const FETCH_LTL_SUMMARY_METRICS = '/ltl/summaryMetrics/fetch';
export const FETCH_LTL_SUMMARY_METRICS_DATA = '/ltl/summaryMetrics/calculate';
export const FETCH_CUSTOMER_ACTIVITY_SUMMARY_METRICS = '/ltl/summaryMetrics/fetch';
export const CARRIER_ACCOUNT_ADD_CARRIER_PARTNER = '/ltl/courierPartnerAccount/add';
export const CARRIER_ACCOUNT_EDIT_CARRIER_PARTNER = '/ltl/courierPartnerAccount/edit';
export const FETCH_CARRIER_INTEGRATION_METADATA = '/ltl/courierPartnerAccount/fetchMetadata';
export const FETCH_ONE_CARRIER_ACCOUNT_PARTNER_DETAILS = '/ltl/courierPartnerAccount/fetchOne';
export const UPDATE_CARRIER_ACCOUNT_STATUS ='/ltl/courierPartnerAccount/updateStatus';
export const FETCH_CUSTOMER_ACTIVITY_FEEDBACK_SUMMARY_METRICS_DATA =
'/Dashboard/summarymetricsapi/getsummarymetricsforfeedback';
export const FETCH_CUSTOMER_ACTIVITY_SUMMARY_METRICS_DATA =
 '/Dashboard/summarymetricsapi/getsummarymetricsforshipment';
export const LTL_GET_PRINT_LABEL_STREAM = '/print/getStream';
export const LTL_POST_COMMENTS = '/Dashboard/addOpsV2Comments';
export const LTL_GET_ATTEMPT_HISTORY = '/Dashboard/getLTLAttemptHistory?reference_number';
export const LTL_EDIT_CONSIGNMENT = '/Dashboard/editOpsV2Consignment';
export const LTL_EDIT_CONSIGNMENT_TAGS = '/Dashboard/editOpsV2Tags';
export const LTL_FETCH_NDR_SHIPPER_NEXT_STEPS = '/Dashboard/editOpsV2ShipperNextSteps';
// Rider Reconciliation

export const GET_ALL_RIDER_RECONCILIATION_DATA = '/Dashboard/riderReconciliation/tasks?hub_id';
export const FETCH_ONE_RIDER_RECONCILIATION =
  '/Dashboard/riderReconciliation/details?worker_code';
export const SETTLE_BALANCE = '/Dashboard/CreditModule/settleCredits';
export const RECONCILE_CN = '/Dashboard/riderReconciliation/markConsignmentAsArrived';
export const FETCH_TRANSACTIONS = '/Dashboard/riderReconciliation/getReconciliationData';
export const FETCH_RIDERS = hubId => `/CRMDashboard/hub/${hubId}/getAllWorkers`;
export const DOWNLOAD_RIDER_RECONCIlIATION_TRANSACTIONS = '/Dashboard/riderReconciliation/getReconciliationDataCSV';
export const DOWNLOAD_RIDER_RECONCILLATION_CN_LEVEL_SUMMARY = '/Dashboard/riderReconciliation/getConsignmentLevelSummary';
export const DOWNLOAD_RIDER_RECONCILLATION_WORKER_LEVEL_SUMMARY = '/Dashboard/riderReconciliation/getWorkerLevelSummary';

export const RIDER_SEARCH = '/Dashboard/WorkerManagement/worker/active';
export const WEEK_OFF_RIDER_SEARCH = '/FoodDashboard/worker';
export const GET_RIDER_WISE_DETAILS = '/retaildashboard/reconciliation/rider/list';
export const DOWNLOAD_CN_LEVEL_SUMMARY = '/retaildashboard/reconciliation/rider/summary/consignment/csv';
export const DOWNLOAD_WORKER_LEVEL_SUMMARY = '/retaildashboard/reconciliation/rider/summary/csv';
export const GET_RIDER_RECONCILIATION_DATA = '/retaildashboard/reconciliation/rider';
export const GET_TRIP_RECONCILIATION_DATA = '/retaildashboard/reconciliation/trip';
export const RECONCILE_CN_RIDER = '/retaildashboard/reconciliation/rider/reconcileConsignments';
export const RECONCILE_CN_TRIP = '/retaildashboard/reconciliation/trip/reconcileConsignments';
export const SETTLE_CASH_RIDER = '/retaildashboard/reconciliation/rider/settleCash';
export const SETTLE_CASH_TRIP = '/retaildashboard/reconciliation/trip/cash/settle';
export const DOWNLOAD_CN_SUMMARY_EXCEL = '/retaildashboard/reconciliation/trip/summary/csv';
export const DOWNLOAD_CN_SUMMARY_PDF = '/retaildashboard/reconciliation/trip/summary/pdf';
export const DOWNLOAD_RIDER_CN_SUMMARY_PDF = '/retaildashboard/reconciliation/worker/summary/pdf';
export const DOWNLOAD_RIDER_CN_SUMMARY_EXCEL = '/retaildashboard/reconciliation/worker/summary/csv';
export const SHIFT_SEARCH = '/FoodDashboard/shifts/active';
export const UPDATE_CN_COD_AMOUNT_COLLECTED = '/retaildashboard/reconciliation/consignment/updateCodAmountCollectedByRider';
export const GET_ONDEMAND_RECONCILIATION_DATA = '/FoodDashboard/CashSettlement/getRiderReconciliation';
export const GET_CONSIGNMENT_RECONCILIATION_DATA = '/FoodDashboard/consignmentSettlement/getConsignmentsToBeReconciled';
export const RECONCILE_CONSIGNMENTS = '/FoodDashboard/consignmentSettlement/reconcileConsignments';
export const SETTLE_PAYOUT_RIDER = '/FoodDashboard/CashSettlement/settleCodAndPayout';
export const ADD_WORKER_CREDIT_VARIANCE = '/FoodDashboard/CashSettlement/addVariance';

// Invoice
export const GET_ALL_INVOICE_DATA = '/invoice/getDownloadRequests';
export const INVOICE_GENERATE = '/invoice/generate';
export const INVOICE_GENERATE_BULK = '/invoice/generateBulk';
export const INVOICE_GENERATE_PDF = '/invoice/generatePdf';
export const INVOICE_LTL_GENERATE = '/invoice/Trip/generate';
export const GET_SERVICE_TYPE_LIST = '/serviceTypes/ops/get';
export const SEND_INVOICE = '/invoice/sendInvoice';
export const DOWNLOAD_LOG_FILE = '/invoice/downloadLog';
export const FREEZE_INVOICE = '/invoice/freeze';
export const MARK_SETTLED = '/invoice/updateInvoicesData';
export const REMOVE_CN_FROM_FROZEN_INVOICE = '/CRMDashboard/invoice/removeConsignmentFromFrozenInvoice';

// Customer Activity
export const GET_FEEDBACK_SHIPMENT = '/Dashboard/customeractivity/shipment';
export const GET_FEEDBACK_FEEDBACK = '/Dashboard/customeractivity/feedback';

export const CREATE_DOWNLOAD_SHIPMENT_FEEDBACK = '/Dashboard/customeractivity/shipment/download';
export const CREATE_DOWNLOAD_SHIPMENT_FEEDBACK_V2 = '/Dashboard/customeractivity/shipment/download/v2';
export const CREATE_DOWNLOAD_FEEDBACK_FEEDBACK = '/Dashboard/customeractivity/feedback/download';
export const CREATE_DOWNLOAD_FEEDBACK_FEEDBACK_V2 = '/Dashboard/customeractivity/feedback/download/v2';
export const GET_DOWNLOADS_REQUESTS_V2 = '/Dashboard/getAllDownloadRequests/v2';

export const GET_DOWNLOAD_REQUESTS_CUSTOMER_ACTIVITY =
 '/Dashboard/customeractivity/getDownloadRequests';
export const GET_TOTES_DATA = '';
export const GET_TOTES_SUMMARY_METRICS = '';

// FoodDashboard
export const GET_DISPATCH_SCREEN_DATA_V2 = '/FoodDashboard/getDispatchScreenData';
export const VIEW_ROSTER_DATA = '/FoodDashboard/getRoster';
export const FETCH_HUB_STATE_DATA = '/FoodDashboard/getHubStateDetails'; 
export const UPDATE_HUB_STATE_DATA = '/FoodDashboard/updateHubState'; 
export const REASSIGN_RIDER = '/FoodDashboard/reassignOrder';
export const MARK_DELIVERED_ONDEMAND = '/FoodDashboard/markDelivered';
export const GIVE_BREAK_RIDER = '/FoodDashboard/setWorkerBreak';
export const FORCE_CHECKOUT_RIDER = '/FoodDashboard/workerForceCheckout';
export const GET_RIDER_LIVE_TRACKING_DATA = '/FoodDashboard/liveOrders';
export const GET_ORDER_HISTORY_DATA = '/FoodDashboard/pastOrders';
export const GET_RIDER_MANAGEMENT_DATA = '/FoodDashboard/getHubWorkers';
export const GET_RIDER_ORDER_HISTORY_DATA = '/FoodDashboard/getWorkerOrders';
export const GET_ROSTER_MANAGEMENT_DATA = '/FoodDashboard/getShift';
export const CREATE_SHIFT = '/FoodDashboard/createShift';
export const EXTEND_SHIFT = '/FoodDashboard/updateShift';
export const GET_RIDER_DETAILS = '/FoodDashboard/riderDetails';
export const UPDATE_RIDER_TEMPERATURE = '/FoodDashboard/updateRiderTemperature';
export const GET_STORE_DETAILS = '/Fooddashboard/storeConfiguration/details';
export const UPDATE_STORE_CONFIGURATION = '/Fooddashboard/storeConfiguration/update';
export const GET_FILTER_MASTER_DATA = '/FoodDashboard/analytics/getFilterMasterData';
export const ADD_RIDER = '/Dashboard/WorkerManagement/worker/create';
export const MANAGE_RIDER = '/Dashboard/WorkerManagement/worker';
export const MARK_RIDER_INSTORE = '/FoodDashboard/markWorkerAsInstore';
export const BULK_RIDER_UPLOAD = '/Dashboard/WorkerManagement/worker/csvupload';
export const SAMPLE_CSV_ADD_RIDER =
  '/Dashboard/WorkerManagement/worker/sample_csv_create_worker.csv';
export const UPDATE_RIDER_KM_READING = '/FoodDashboard/updateKmReading';
export const GET_DEACTIVATED_RIDER_MANAGEMENT_DATA = '/FoodDashboard/getDisabledWorkers';
export const FETCH_ALLOCATION_RULES_DATA = '/FoodDashboard/fetchAllocationRules';
export const UPDATE_ALLOCATION_RULES_DATA = '/FoodDashboard/updateAllocationRules';
export const BULK_RIDER_UPDATE = '/Dashboard/WorkerManagement/worker/update/csvupload';
export const DOWNLOAD_RIDER_ACTIVE = '/Dashboard/WorkerManagement/csv/activeWorkers.csv';
export const DOWNLOAD_RIDER_INACTIVE = '/Dashboard/WorkerManagement/csv/disabledWorkers.csv';
export const DOWNLOAD_RIDERS_CSV = '/FoodDashboard/sendRiderDataCSVRequest';
export const GET_RIDER_DATA_DOWNLOAD_REQUESTS = '/FoodDashboard/getRiderDataDownloadRequests';
export const MARK_REACHED_AT_HUB = '/FoodDashboard/markReachedAtHub';
export const SAMPLE_CSV_UPDATE_ORDERS = '/FoodDashboard/sample_csv_mark_reached_at_hub.csv';
export const GET_BULK_UPDATE_ORDER_REQUESTS = '/FoodDashboard/getBulkCSVUploadRequests';
export const ADD_ORDERS_CSV_UPLOAD = '/FoodDashboard/createOrder/upload';
export const COD_WORKER_DATA_LIST = '/FoodDashboard/CashSettlement/listWorkerBalance';
export const COD_TRANSACTION_HISTORY_DATA_LIST = '/FoodDashboard/CashSettlement/history';
export const COD_GET_CONSIGNMENTS = '/FoodDashboard/CashSettlement/workerCreditRequests';
export const COD_UPDATE_REQUESTS = '/FoodDashboard/CashSettlement/updateWorkerCredit';
export const COD_ADHOC_CASH = '/FoodDashboard/CashSettlement/addCash';
export const COD_ADD_PETTY_CASH = '/FoodDashboard/CashSettlement/updateWorkerCredit';
export const REALLOCATE_RIDER = '/FoodDashboard/reallocateOrder';
export const GET_TERMS_AND_CONDITIONS = '/FoodDashboard/getRiderTermsAndConditions';
export const SET_TERMS_AND_CONDITIONS = '/FoodDashboard/setRiderTermsAndConditions';
export const GET_DELIVERY_PATH = '/FoodDashboard/getDeliveryPath';
export const SHOW_DISPATCH_V2_FOR_HUB = '/FoodDashboard/showDispatchV2';
export const UPDATE_ORDER_DETAILS = '/FoodDashboard/updateOrderDetails';
export const SETTLE_PAYOUT_AMOUNT = '/FoodDashboard/CashSettlement/settlePayout';
export const GET_CLUBBING_CONFIG_MAPPINGS = '/FoodDashboard/mappings';
export const ADD_CLUBBING_CONFIG_MAPPING = '/FoodDashboard/addMapping';
export const UPDATE_CLUBBING_CONFIG_MAPPING = '/FoodDashboard/updateMapping';
export const DELETE_CLUBBING_CONFIG_MAPPING = '/FoodDashboard/deleteMapping';
export const DELETE_BULK_CLUBBING_CONFIG_MAPPINGS = '/FoodDashboard/delete/BulkMapping';
export const GET_CLUBBING_CONFIGS = '/FoodDashboard/settings';
export const ADD_CLUBBING_CONFIG = '/FoodDashboard/addSetting';
export const UPDATE_CLUBBING_CONFIG = '/FoodDashboard/updateSetting';
export const DELETE_CLUBBING_CONFIG = '/FoodDashboard/deleteSetting';
export const GET_DISPATCH_SCREEN_SUMMARY_METRICS = '/FoodDashboard/getDispatchScreenSummaryMetrics';
export const GET_WEATHER_FORECAST = '/FoodDashboard/weatherForecast';
export const MARK_RTO_DELIVERED = '/FoodDashboard/markConsignmentRtoDelivered';
export const MARK_RTO_UNDELIVERED = '/FoodDashboard/markConsignmentRtoUndelivered';
export const MARK_ORDER_UNDELIVERED = '/FoodDashboard/markUndelivered';
export const DELETE_CLUBBING_MAPPING = '/FoodDashboard/deleteMapping';
export const GET_RTO_PATH = '/FoodDashboard/getRtoPath';
export const DETACH_REUSABLE_ASSETS = '/FoodDashboard/detachReturnableAssets';

export const GET_PLANNED_SHIFT = '/FoodDashboard/getPlannedShift';
export const CREATE_PLANNED_SHIFT = '/FoodDashboard/createPlannedShift';
export const UPDATE_PLANNED_SHIFT = '/FoodDashboard/editPlannedShift';
export const DELETE_PLANNED_SHIFT = '/FoodDashboard/deactivatePlannedShift';
export const DUPLICATE_PLANNED_SHIFT = '/FoodDashboard/deactivateAndDuplicatePlannedShift';
export const DEACTIVATE_PLANNED_SHIFT = '/FoodDashboard/deactivatePlannedShift';
export const GET_WEEK_OFFS = '/FoodDashboard/worker/weekOffDays';
export const UPLOAD_WEEK_OFFS = '/FoodDashboard/worker/weekOffs';
export const UPDATE_WEEK_OFFS = (id) => `/FoodDashboard/worker/${id}/weekOffs`;
export const DELETE_WEEK_OFFS = (id) => `/FoodDashboard/worker/${id}/weekOffs`;
export const SAMPLE_CSV_WEEK_OFFS = '/FoodDashboard/worker/sample_csv_rider_weekoffs.csv';
export const CREATE_WEEK_OFFS = '/FoodDashboard/worker/weekOffDay';
export const GET_CUSTOMERS = '/FoodDashboard/getCustomers';
export const SET_CUSTOMER = '/FoodDashboard/customer';
export const GET_ORDER_NUMBER = '/FoodDashboard/getOrderNumber';
export const ADD_SINGLE_ORDER = '/FoodDashboard/createOrder/singleUpload';
export const DELETE_CUSTOMER = '/FoodDashboard/deleteCustomer';
export const FETCH_SOFTDATA_UPLOAD_CONFIG = '/Dashboard/softdataUpload/fetch/config';

export const GET_CONFIG_OBJECTS = '/FoodDashboard/hubAllocationConfig/fetchConfigObjects';
export const SET_CONFIG_OBJECTS = '/FoodDashboard/hubAllocationConfig/updateConfigObject';
export const GET_CONFIG_MAPPINGS = '/FoodDashboard/hubAllocationConfig/fetchMappings';
export const SET_CONFIG_MAPPINGS = '/FoodDashboard/hubAllocationConfig/updateMapping';
export const SET_HUB_CONFIG_MAPPING = '/FoodDashboard/hubAllocationConfig/updateHubMapping';
export const GET_HUB_LIST_SAMPLE = '/FoodDashboard/hubAllocationConfig/sample_hub_code_list.csv';
export const GET_CUSTOMER_LIST_SAMPLE = '/FoodDashboard/hubAllocationConfig/sample_customer_code_list.csv';
export const ADD_RIDER_COMMENT = '/Dashboard/WorkerManagement/worker/comments/create';
export const FETCH_RIDER_PAYOUT_TYPES = '/pricedata/getCharges';
export const FETCH_RIDER_PAYOUT_DATA = '/pricedata/get';
export const DOWNLOAD_RIDER_PAYOUT_DATA = '/pricedata/download';
export const DOWNLOAD_RIDER_PAYOUT_SAMPLE_CSV = '/pricedata/getCSV';
export const UPLOAD_RIDER_PAYOUT_SAMPLE_CSV = '/pricedata/update';
export const DELETE_PAYOUT = '/pricedata/deleteMultiple';
export const DELETE_PAYOUT_SINGLE = '/pricedata/delete';
export const UPDATE_PAYOUT = '/pricedata/update';

export const GET_PARKING_CONFIG = '/FoodDashboard/limitWorkersInStoreByVehicleCategory/getConfig';
export const DELETE_PARKING_CONFIG = '/FoodDashboard/limitWorkersInStoreByVehicleCategory/deleteConfig';
export const CREATE_PARKING_CONFIG = '/FoodDashboard/limitWorkersInStoreByVehicleCategory/createConfig';
export const EDIT_PARKING_CONFIG = '/FoodDashboard/limitWorkersInStoreByVehicleCategory/updateConfig';
export const ACKNOWLEDGE_CANCELLED_ORDERS = '/FoodDashboard/setCancelAcknowledgement';
export const GET_COD_HUB_CONFIG = '/FoodDashboard/fetchHubCODConfiguration';
export const ADD_COD_HUB_CONFIG = '/FoodDashboard/storeHubCODConfiguration';
export const DELETE_COD_HUB_CONFIG = '/FoodDashboard/deleteHubCODConfiguration';

export const MARK_CANCELLED_ONDEMAND = '/FoodDashboard/cancelOrder';
export const SEND_COD_TRANSACTION_DATA_DOWNLOAD_REQUEST =
          '/FoodDashboard/sendCodTransactionDataDownloadRequest';
export const GET_COD_TRANSACTION_DATA_DOWNLOAD_REQUESTS =
          '/FoodDashboard/getCodTransactionDataDownloadRequests';
export const GET_DISPATCH_SCREEN_ANALYTICS = '/FoodDashboard/getDispatchScreenAnalytics';
export const UNBLOCK_RIDER_LOGIN_OTP = '/FoodDashboard/unblockRiderLoginOTP';
export const RIDER_ASSIGNMENT = '/FoodDashboard/riderAssignment';
export const MARK_READY_TO_DISPATCH = '/FoodDashboard/sample_csv_mark_reached_at_hub.csv';
export const GET_ORDER_TRACKING_DIRECTIONS = '/FoodDashboard/getOrderManagementTrackingDirections';
export const SEARCH_RIDERS_ORDER_ASSIGNMENT = '/FoodDashboard/ridersForOrderAssignment';
export const BULK_COD_SETTLEMENT = '/FoodDashboard/CashSettlement/createJob';
export const GET_DOWNLOADS_REQUESTS_COD_SETTLEMENT = '/FoodDashboard/CashSettlement/getJobs';
export const GET_DOWNLOAD_REPORT_COD_SETTLEMENT = '/FoodDashboard/CashSettlement/getJob';
export const DOWNLOAD_SAMPLE_BULK_COD_SETTLE_FILE = '/FoodDashboard/CashSettlement/getSampleCsv';
export const UPDATE_WORKER_ELIGIBILITY = '/FoodDashboard/updateWorkerEligibility';
// Generic Analytics

export const GET_CHART_DATA = '/Dashboard/analytics/getByChart';
export const GET_CHART_DATA_RETAIL = '/Dashboard/retail/analytics/getByChart';
export const GET_CHART_DATA_ONDEMAND = '/FoodDashboard/analytics/getByChart';
export const GET_CHART_DATA_TMS = '/TmsDashboard/analytics/v1/getByChart';
export const GET_RIDER_DPH_DATA = '/FoodDashboard/hubRiderMetrics';
export const GET_REPORTING_TABLE_DATA = '/FoodDashboard/fetchReports';
export const GET_REPORTING_TABLE_DOWNLOAD_DATA = '/FoodDashboard/fetchReports/download';
export const GET_DOWNLOADS_REQUESTS_RIDER_REPORTING = '/FoodDashboard/getAllDownloadRequests';
export const GENERATE_REPORTS_RETAIL = '/CRMDashboard/trips/download';
export const GET_CRM_SUMMARY_METRICS_DATA = '/crmdashboard/metrics/summarymetrics';
export const GET_CRM_SUMMARY_METRICS_AGGREGATED_DATA = '/crmdashboard/metrics/summaryMetrics/aggregatedMetrics';
export const GET_SUMMARY_METRICS_DOWNLOAD_DATA = '/crmdashboard/metrics/summaryMetrics/download';
export const SEARCH_RIDERS = '/Dashboard/WorkerManagement/worker/searchWorker';
export const RETAIL_REPORT_DOWNLOAD = '/RetailDashboard/tripdetails/getslp';
export const FLM_TRIP_CLOSURE_DOWNLOAD = '/RetailDashboard/action/v1/printFLMTripClosureSheet';
export const MM_TRIP_CLOSURE_DOWNLOAD = '/TMSDashboard/ist/v1/printMMTripClosureManifest';
export const CALCULATE_SIMULATED_ETA = '/RetailDashboard/tripdetails/calculateSimulatedEta';

// add to trips retails
export const CREATE_ROUTE_ASYNC_ADD_TO_TRIP = '/retaildashboard/consignments/async/addToExistingTrips';
export const CREATE_ROUTE_ADD_TO_TRIP = '/retaildashboard/consignments/addToExistingTrips';
export const CREATE_ROUTE_ALL_ASYNC_ADD_TO_TRIP = '/retaildashboard/consignments/allAsync/addToExistingTrips';
export const CREATE_ROUTE_ALL_ADD_TO_TRIP = '/retaildashboard/consignments/all/addToExistingTrips';
// apis for erp sync modal
export const BOOKING_STATUS_UPDATE = '/Dashboard/erpsynclogs/bookingstatusupdate';
export const SOFTDATA_UPDATE = '/Dashboard/getSoftdataUploadLogs';
export const COURIER_BOOKING_UPDATE = '/dashboard/erpsynclogs/v1/fetchCourierPartnerBookingLog';
export const COURIER_PARTNER_TRACKING_UPDATE = '/dashboard/erpsynclogs/v1/fetchCourierPartnerTrackingLog';
export const EWB_UPDATE = '/Dashboard/erpsynclogs/ewbUpdate';
export const HANDLING_UNIT_PUSH_UPDATE = '/dashboard/erpsynclogs/v1/fetchHUBookingLog';
export const CONSIGNMENT_MANIFEST = '/Dashboard/erpsynclogs/deliverymanifest';
export const DMC_SCAN_DETAILS = '/Dashboard/erpsynclogs/fetchDmcScanDetails';
export const STOP_DELIVERY_DETAILS = '/Dashboard/erpsynclogs/fetchStopDeliveryDetails';
export const DELIVERY_STATUS_UPDATE = '/Dashboard/erpsynclogs/deliverystatusupdate';
export const TRIP_UPDATE = '/Dashboard/erpsynclogs/tripupdate';
export const FDM_HANDOVER_UPDATE = '/Dashboard/erpsynclogs/getFDMHandoverLogs';
export const CLIENT_TRACKING_STATUS_UPDATE =
  '/Dashboard/erpsynclogs/getClientTrackingStatusUpdate';
export const FDM_CTBS_UPDATE = '/Dashboard//erpsynclogs/getFDMCTBSSyncLogs';
export const BRIM_BOOKING_UPDATE = '/Dashboard/erpsynclogs/getBookingBRIMLogs';
export const EWAY_EXTENSION = '/Dashboard/erpsynclogs/ewaybillextension';
// apis for navbar related functionalities
export const CONSIGNEMNT_TRACKING_OPS = '/Dashboard/Consignments/consignmentevents';
export const CONSIGNMENT_TRACKING_PRINT_OPS = '/Dashboard/printConsignmentEvents';
export const CHANGE_PASSWORD = '/Dashboard/me/changepassword';
export const FETCH_SERVICEABILTIY_INFO = '/Dashboard/getTAT';

export const MARK_CONSIGNMENT_AS_DELIVERED =
  '/CRMDashboard/consignments/markConsignmentAsDelivered';
export const MARK_CONSIGNMENT_AS_DELIVERED_V2 =
  '/CRMDashboard/consignments/markConsignmentAsDelivered/v2';

// bank deposit
export const GET_BANK_DEPOSIT_HUB_BALANCE = '/CRMDashboard/riderReconciliation/hubBalance';
export const GET_BANK_DEPOSIT_REPORT = '/CRMDashboard/riderReconciliation/depositReport';
export const CREATE_BANK_TRANSACTION = '/CRMDashboard/riderReconciliation/transaction/create';
export const GET_BANK_DEPOSIT_S3_URL = '/CRMDashboard/riderReconciliation/getS3Url';
export const DOWNLOAD_EMPLOYEE_LEVEL_REPORT = '/CRMdashboard/employeeReconciliation/csv/pendingBankDepositHubEmployeeLevel.csv';
export const DOWNLOAD_CN_LEVEL_REPORT = '/CRMdashboard/employeeReconciliation/csv/pendingBankDepositCnLevel.csv';
export const GET_BALANCE_FOR_CMS = '/CRMDashboard/riderReconciliation/getBalanceForCMS';
export const CREATE_CMS_TRANSACTION = '/CRMDashboard/riderReconciliation/transaction/createForCMS';
export const GET_BANK_DEPOSIT_REPORT_CSV = '/CRMDashboard/riderReconciliation/depositReportCSV';
export const UPDATE_BANK_TRANSANCTION = '/CRMDashboard/bank-deposit/transaction/v1/update';

//cash remittance
export const GET_REMITTANCE_DATA = '/CRMDashboard/remittance/fetch';
export const GET_REMITTANCE_DATA_V1 = '/CRMDashboard/remittance/v1/fetch';
export const GET_CONSIGNMENTS_FOR_REMITTANCE = '/CRMDashboard/remittance/fetchCnEligibleForRemittanceByCustomer';
export const LOG_REMITTANCE = '/CRMDashboard/remittance/create-bulk';
export const SETTLE_REMITTANCE = '/CRMDashboard/remittance/settle';
export const DOWNLOAD_REMITTANCE_SAMPLE_CSV = '/CRMDashboard/remittance/download-sample-csv';
export const DOWNLOAD_REMITTANCE_CN_DATA = '/CRMDashboard/remittance/download-cn-remittance-data';
export const FETCH_REMITTANCE_DETAILS_DATA = '/CRMDashboard/remittance/fetch/summary';
//Third Party Integrations

export const ASSIGN_CARRIERS = '/CRMDashboard/assignCourierPartner';
export const CARRIER_HANDOVER = '/CRMDashboard/handoverCourierPartner';
export const CARRIER_BOOKING = '/CRMDashboard/courierBooking';
export const FETCH_COURIER_PARTNER_DETAILS = '/CRMDashboard/consignment/courierPartner/fetch';
export const FETCH_COURIER_PARTNER_DETAILS_BULK = '/CRMDashboard/consignment/courierPartner/fetchMultipleCnDetails';
export const GENERATE_CARRIER_AWB_NUMBER = '/CRMDashboard/assignCourierPartnerReferenceNumber';
export const RETRY_PRINT_MANIFEST = '/RetailDashboard/consignments/bulklabel/v1/retryRequest';
export const FETCH_COURIER_CHANGE_REASONS_LIST = '/Dashboard/ConfigurationSetup/reasonMaster';
export const BULK_ASSIGN_CARRIER_SAMPLE_CSV = '/CRMDashboard/sample_csv_third_party_assign.csv';
export const REVOKE_COURIER_HANDOVER = '/CRMDashboard/revokeCourierHandover';
export const FORCE_POLLING = '/CRMDashboard/consignments/tracking/forcePolling';

// TMS Dashboard
export const GET_ALL_TMS_DATA = '/TMSDashboard/vehicle/v1/fetchData';
export const FETCH_TMS_SUMMARY_METRICS_DATA = '/TMSDashboard/vehicle/v1/getBucketSummary';
export const FETCH_TMS_MASTERS = '/TMSDashboard/hub/v1/fetchMasters';
export const FETCH_TMS_ROUTES_ORIGIN_HUB = '/TMSDashboard/hub/v1/fetchRoutes';
export const ASSIGN_DOCK_TMS = '/TMSDashboard/vehicle/v1/dockAssign';
export const FETCH_TMS_VEHICLES = '/TMSDashboard/vehicle/v1/autoFill';
export const DOCK_INSCAN_TMS = '/TMSDashboard/vehicle/v1/dockInScan';
export const DOCK_OUTSCAN_TMS = '/TMSDashboard/vehicle/v1/dockOutScan';
export const GATE_IN_TMS = '/TMSDashboard/vehicle/v1/gateIn';
export const GATE_OUT_TMS = '/TMSDashboard/vehicle/v1/gateOut';
export const SEARCH_VEHICLES_TMS = '/TMSDashboard/vehicle/v1/search';
export const GET_ALL_TMS_DATA_IST = '/TMSDashboard/ist/v1/fetchData';
export const TMS_UPDATE_ROUTES_IN_SETUP = '/TMSDashboard/setup/route/v1/updateRoutes';
export const TMS_DOWNLOAD_SAMPLE_CSV_ROUTE_MASTER_ENABLE_DISABLE =
  '/tmsdashboard/setup/route/v1/sample_csv_route_master_update.csv';
export const FETCH_TMS_SUMMARY_METRICS_DATA_IST = '/TMSDashboard/ist/v1/getBucketSummary';
export const FETCH_TMS_IST_DETAILS = '/TMSDashboard/ist/v1/getDetails';
export const DOWNLOAD_TMS_IST_DATA = '/TMSDashboard/ist/v1/download';
export const DOWNLOAD_TMS_VEHICLE_DATA = '/TMSDashboard/vehicle/v1/download';
export const SCAN_ITEMS_TMS = '/TMSDashboard/ist/v1/scanItem';
export const CREATE_IST_TMS = '/TMSDashboard/ist/v1/create';
export const GET_LOADING_STATUS_TMS_IST = '/TMSDashboard/ist/v1/getLoadingStatus';
export const START_IST_LOADING_TMS = '/TMSDashboard/ist/v1/startLoading';
export const GET_HUBS_IN_IST_ROUTE = '/TMSDashboard/hub/v1/getRouteHub';
export const SAVE_DRAFT_CREATE_IST = '/TMSDashboard/ist/v1/saveDraft';
export const IST_RECEIVE_TMS = '/TMSDashboard/ist/v1/receive';
export const IST_DEPART_TMS = '/TMSDashboard/ist/v1/depart';
export const IST_CLOSE_TMS = '/TMSDashboard/ist/v1/close';
export const FREEZE_IST = '/TMSDashboard/ist/v1/freeze';
export const GET_UNLOADING_STATUS_TMS_IST = '/TMSDashboard/ist/v1/getUnloadingStatus';
export const START_IST_UNLOADING_TMS = '/TMSDashboard/ist/v1/startUnloading';
export const SAVE_DRAFT_IST_UNLOADING = '/TMSDashboard/ist/v1/saveUnloadingDraft';
export const TMS_IST_FINISH_UNLOADING = '/TMSDashboard/ist/v1/finishUnloading';
export const TMS_UPLOAD_ROUTES_IN_SETUP = '/TMSDashboard/setup/route/v1/createMultiple';
export const GET_ALL_TMS_DATA_SETUP = '/TMSDashboard/setup/route/v1/fetch';
export const TMS_SETUP_SEARCH_ROUTES = '/TMSDashboard/route/v1/search';
export const TMS_SETUP_GET_ROUTE_DETAILS = '/TMSDashboard/route/v1/getDetails';
export const GET_ALL_TMS_DATA_SETUP_CONNECTIONS = '/TMSDashboard/setup/connection/v1/fetch';
export const TMS_UPLOAD_CONNECTIONS_IN_SETUP = '/TMSDashboard/setup/connection/v1/createMultiple';
export const FETCH_TRANSPORTERS_TMS = '/TMSDashboard/v1/fetchTransporters';
export const PRINT_MANIFEST_TMS = '/TMSDashboard/vehicle/v1/printManifest';
export const PRINT_MANIFEST_ASYNC_TMS = '/TMSDashboard/vehicle/v1/printManifestAsync';
export const GET_ALL_TMS_DATA_SETUP_EXCEPTION_BAGS = '/TMSDashboard/setup/bagException/v1/fetch';
export const GET_ALL_TMS_DATA_SETUP_EXCEPTION_VEHICLES = '/TMSDashboard/setup/vehicleException/v1/fetch';
export const TMS_UPLOAD_BAG_EXCEPTIONS_IN_SETUP = '/TMSDashboard/setup/bagException/v1/createMultiple';
export const TMS_UPLOAD_VEHICLE_EXCEPTIONS_IN_SETUP = '/TMSDashboard/setup/vehicleException/v1/createMultiple';
export const TMS_UPDATE_VEHICLE_EXCEPTIONS_IN_SETUP = '/TMSDashboard/setup/vehicleException/v1/updateMultiple';
export const TMS_UPDATE_BAG_EXCEPTIONS_IN_SETUP = '/TMSDashboard/setup/bagException/v1/updateMultiple';
export const APPLY_EXCEPTION_CODE_VEHICLE_TMS = '/TMSDashboard/vehicle/v1/applyException';
export const SEARCH_EXCEPTION_CODES_VEHICLE = '/TMSDashboard/setup/vehicleException/v1/search';
export const SEARCH_EXCEPTION_CODES_BAG = '/TMSDashboard/setup/bagException/v1/search';
export const DOWNLOAD_EXCEPTION_MASTER = '/TMSDashboard/setup/exceptions/v1';
export const DOWNLOAD_ROUTE_MASTER = '/TMSDashboard/setup/route/v1/download_route_hub_masters.csv';
export const TMS_DOWNLOAD_CONNECTIONS_MASTER = '/TMSDashboard/setup/connections/v1/download_connections.csv';
export const TMS_SCAN_ITEM_OFFLOAD = '/TMSDashboard/ist/v1/scanItemForOffload';
export const TMS_OFFLOAD = '/TMSDashboard/ist/v1/offload';
export const GET_ALL_TMS_DATA_SETUP_BAGGING = '/TMSDashboard/setup/baggingMaster/v1/fetch';
export const TMS_DOWNLOAD_SAMPLE_CSV_BAGGING_MASTER =
'/TMSDashboard/setup/baggingMaster/v1/sample_csv_bagging_master.csv';
export const TMS_UPLOAD_BAGGING_HUBS_IN_SETUP = '/TMSDashboard/setup/baggingMaster/v1/createMultiple';
export const TMS_DELETE_BAGGING_HUBS_IN_SETUP = '/TMSDashboard/setup/baggingMaster/v1/deleteMultiple';
export const TMS_CREATE_NEW_BAG = '/Dashboard/bag/v2/createOrFetch';
export const ASSIGN_BAG_THIRD_PARTY = '/Dashboard/bag/v1/getAvailableBagsForCourierAssign';
export const ASSIGN_SCANNED_BAGS_TO_THIRD_PARTY = '/Dashboard/bag/v1/assignCourierPartner';
export const HANDOVER_SCANNED_BAGS_TO_THIRD_PARTY = '/Dashboard/bag/v1/handoverCourierPartner';
export const UPDATE_COURIER_PARTNER_DETAILS = '/Dashboard/bag/v1/updateCourierPartner';
export const GET_AVAILABLE_BAGS_FOR_COURIER_MANIFEST = '/Dashboard/bag/v1/getCourierManifestSiblings';
export const FETCH_BAGS_AVAIlABLE_FOR_HANDOVER = '/Dashboard/bag/v1/getAvailableBagsForCourierHandover';
export const MARK_BAG_EXCEPTION = '/Dashboard/bag/v1/markExceptionOnBag';
export const FETCH_COURIER_PARTNER_ACCOUNT = '/ltl/courierPartnerAccount/fetch';
export const TMS_CREATE_BAG_SAVE_PROGRESS = '/Dashboard/bag/v2/bagging/saveProgress';
export const TMS_SEAL_BAG = '/Dashboard/bag/v2/seal';
export const TMS_AUTOFILL_BAG_DESTINATION_HUBS = '/TMSDashboard/setup/baggingMaster/v1/autofillDestination';
export const BAG_NUMBER_SEARCH = '/Dashboard/bag/v2/debag/search';
export const GET_AVAILABLE_CONSIGNMENTS_FOR_BAG = '/Dashboard/bag/availableConsignmentForGivenBag';
export const TMS_DOWNLOAD_SAMPLE_CSV_CONNECTION_MASTER =
'/TMSDashboard/setup/connection/v1/sample_csv_connection_master.csv';
export const PRINT_MOTHER_BAGGING_LABEL = '/Dashboard/bag/v1/printMotherBaggingLabel';
export const GET_ALL_AVAILABLE_CONSIGNMENTS_FOR_LOADING = '/TMSDashboard/ist/v1/loading/fetchWayBills';
export const GET_MISSING_CONSIGNMENTS_WHILE_LOADING = '/TMSDashboard/ist/v1/checkMissingChildConsignmentLoading';
export const DELETE_MULTIPLE_TRIPS = '/TMSDashboard/ist/v1/deleteMultiple';
export const REASSIGN_VEHICLE_TMS = '/TMSDashboard/ist/v1/reassignVehicle';
export const TMS_DOWNLOAD_SAMPLE_CSV_GATE_MASTER =
  '/TMSDashboard/setup/hubGate/v1/sample_csv_hub_gate_master.csv';
export const TMS_DOWNLOAD_SAMPLE_CSV_DOCK_MASTER =
  '/TMSDashboard/setup/dockingStation/v1/sample_csv_docking_station_master.csv';
export const TMS_UPLOAD_GATES_IN_SETUP = '/TMSDashboard/setup/hubGate/v1/createMultiple';
export const TMS_UPLOAD_DOCKS_IN_SETUP = '/TMSDashboard/setup/dockingStation/v1/createMultiple';
export const GET_ALL_TMS_DATA_SETUP_GATES = '/TMSDashboard/setup/hubGate/v1/fetch';
export const GET_ALL_TMS_DATA_SETUP_DOCKS = '/TMSDashboard/setup/dockingStation/v1/fetch';
export const TMS_DOWNLOAD_DUMP_GATE_MASTER = '/TMSDashboard/setup/hubGate/v1/all_hub_gates.csv';
export const TMS_DOWNLOAD_DUMP_DOCK_MASTER =
  '/TMSDashboard/setup/dockingStation/v1/all_docking_station.csv';
export const TMS_DOWNLOAD_DUMP_BAGGING_MASTER =
  '/TMSDashboard/setup/baggingMaster/v1/download_bagging_masters.csv';
export const REMOVE_FROM_LOADING_DRAFT = '/TMSdashboard/ist/v1/loadingDraft/remove';
export const UPDATE_LOADING_DRAFT = '/TMSdashboard/ist/v1/loadingDraft/update';
export const VALIDATE_CONSIGNMENTS_MM = '/TMSdashboard/ist/v1/validateWayBills.csv';
export const REMOVE_FROM_UNLOADING_DRAFT = '/TMSdashboard/ist/v1/unloadingDraft/remove';
export const UPDATE_UNLOADING_DRAFT = '/TMSdashboard/ist/v1/unloadingDraft/update';
export const FETCH_VEHICLE_MAKE_LIST = '/Tmsdashboard/v1/fetchVehicleMakeList';
export const CREATE_PTL_TRIP = '/TMSDashboard/ist/v1/ptl/create';
export const PTL_CONSIGNMENT_INSCAN = '/TMSDashboard/ist/v1/ptl/inscan';
export const DOWNLOAD_IST_CONSIGNMENTS_DATA = '/TMSDashboard/ist/v1/downloadConsignmentsAndBags';
export const UPDATE_KM_READING = '/Tmsdashboard/ist/v1/updateKMReading';
export const TMS_DOWNLOAD_SAMPLE_CSV_ROUTE_MASTER =
  '/tmsdashboard/setup/route/v1/sample_csv_route_master.csv';
export const UPDATE_SELECTIVE_FIELDS = '/TMSDashboard/ist/v1/update';
export const BULK_UPDATE_IST_DETAILS = '/TMSDashboard/ist/v1/bulkUpdate';
export const FETCH_IST_CONSIGNMENT_EXCEPTIONS = '/TMSDashboard/ist/v1/fetchIstConsignmentExceptions';
export const GET_ALL_VEHICLE_ATTENDANCE_DATA = '/TMSDashboard/vehicle/attendance/v1/get';
export const GET_ALL_VEHICLE_ATTENDANCE_DETAILS = '/TMSDashboard/vehicle/attendance/v1/getDetails';
export const SET_MARK_PRESENT = '/TMSDashboard/vehicle/attendance/v1/markPresent';
export const SET_MARK_ABSENT = '/TMSDashboard/vehicle/attendance/v1/markAbsent';
export const SHOW_WARNING_BEFORE_SET_MARK_ABSENT = '/TMSDashboard/vehicle/attendance/v1/forWarningBeforeMarkAbsent';
export const DOWNLOAD_ATTENDANCE = '/TMSDashboard/vehicle/attendance/v1/download';
export const BULK_UPLOAD_VEHICLE = '/Dashboard/vehiclemanagement/vehicle/create/upload';
export const BULK_UPLOAD_VENDOR = '/Dashboard/vehiclevendormanagement/vehiclevendor/create/upload';
export const GET_VEHICLE_CATEGORY_LIST = '/Dashboard/vehiclemanagement/getcategorylist';
export const GET_VEHICLE_LIST = '/Dashboard/vehiclemanagement/get';
export const GET_VENDOR_LIST = '/Dashboard/vehiclemanagement/getvendorlist';
export const GET_BAG_SERIES = '/series/ops/bag/getSeries';
export const ADD_BAG_SERIES = '/series/ops/bag/addSeries';
export const DELETE_BAG_SERIES = '/series/ops/bag/deleteSeries';
export const TMS_DOWNLOAD_SAMPLE_CSV_BAG_EXCEPTION_MASTER =
  '/TMSDashboard/setup/bagException/v1/sample_bag_exception_master.csv';
export const GET_VEHICLE_DOCUMENTS = '/Dashboard/vehiclemanagement/getvehicledocument';
export const UPDATE_VEHICLE_DOCUMENT = '/Dashboard/vehiclemanagement/updatevehicledocument';
export const ADD_VEHICLE_DOCUMENT = '/Dashboard/vehiclemanagement/updatevehicledocument';
export const DOWNLOAD_VEHICLE_DOCUMENTS_CSV = '/Dashboard/vehiclemanagement/csv/vehicledocument.csv';

//TAT Masters Setup
export const GET_BOOKING_MASTER_HEADERS = '/pricedata/getCharges';
export const GET_BOOKING_MASTER_TABLE_DATA = '/pricedata/get';
export const BOOKING_MASTER_DOWNLOAD_DUMP = '/pricedata/download';
export const BOOKING_MASTER_SAMPLE_CSV_DOWNLOAD = '/pricedata/getCSV';
export const BOOKING_MASTER_UPDATE = '/pricedata/update';
export const BOOKING_MASTER_DELETE = '/priceData/delete';
export const GET_CONTRACT_CODES = '/pricedata/getContractCodes';
export const ADD_OR_UPDATE_CONTRACT_CODES = '/pricedata/addOrUpdateContractCodes';
export const FETCH_VENDOR_ACCOUNTS = '/pricedata/fetchVendorAccount';
export const LINK_MULTIPLE_CONTRACTS = '/pricedata/linkContractCodesBulk';

//shelf
export const GET_SHELF_TABLE_DATA = '/Dashboard/shelf/manage/getAllShelves';
export const ADD_CONSIGNMENTS_TO_SHELF = '/Dashboard/shelf/addConsignments';
export const GET_ALL_CONSIGNMENTS_FROM_SHELF = '/Dashboard/shelf/getAllConsignmentFromShelf';
export const REMOVE_CONSIGNMENTS_FROM_SHELF = '/Dashboard/shelf/removeConsignmentsMultiple';
export const GET_VEHICLE_LOCATION = '/TMSDashboard/vehicle/track';

//crate
export const GET_CRATE_TABLE_DATA = '/Dashboard/crate/getAllCrates';
export const ADD_CRATE_MAPPING_BULK = '/Dashboard/crate/bulk/create';
export const SAMPLE_CSV_DOWNLOAD_LINK = '/Dashboard/crate/sample_csv_create_crate.csv';

//supply-request
export const GET_SUPPLY_REQUEST_TABLE_DATA = '/CRMDashboard/supplyrequest/fetch';
export const GET_SUPPLY_REQUEST_DETAILS = '/CRMDashboard/supplyrequest/fetchOne';
export const MARK_SUPPLY_REQUEST_AS_DELIVERED = '/CRMDashboard/markSupplyRequestAsDelivered';
export const MARK_SUPPLY_REQUEST_AS_UNDELIVERED = '/CRMDashboard/markSupplyRequestAsUnDelivered';
export const CREATE_SUPPLY_REQUEST = '/CRMDashboard/supplyrequest';

// api for s3 signed URL
export const GET_S3_PUT_OBJECT = '/FoodDashboard/helpdeskNotifications/upload';
export const GET_S3_PUT_CERTIFICATE_OBJECT = '/Dashboard/VaccinationCertificate/upload';

// helpdesk notifications
export const ADD_UPDATE_NOTIFICATION = '/FoodDashboard/helpdeskNotifications/update';
export const GET_NOTIFICATIONS = '/FoodDashboard/helpdeskNotifications/get';
export const SEARCH_ALL_WORKERS = '/Dashboard/WorkerManagement/worker/searchAllWorkers';

//trip reconciliation ril express
export const GET_TRIP_RECONCILIATION_DETAILS = '/retaildashboard/tripmanager/reconciliation/get';
export const RECONCILE_TRIP_CONSIGNMENTS = '/retaildashboard/tripmanager/reconciliation/reconcile';
export const RECONCILE_COD = '/retaildashboard/tripmanager/reconciliation/settle';
export const PRINT_TRIP_MANIFEST = '/Dashboard/fetchIstTripManifest';
export const PRINT_CONSIGNMENT_LABEL = '/Dashboard/Consignments/generateconsignmentlabelStream';
export const PRINT_CN_COPY_LABEL = '/Dashboard/consignments/v1/printConsignmentCopy';
export const PRINT_DELIVERY_NOTE = '/Dashboard/external/v1/printDeliveryNote';

export const CREATE_DOWNLOAD_REQUEST_TRIPS_V2 = '/CRMDashboard/trips/v2/download';

// counter booking
export const GET_BOOKING_SAVED_ADDRESSES = '/businessAddress/ops/search';
export const EDIT_BOOKING_SAVED_ADDRESS = '/businessAddress/ops/edit';
export const SAVE_BOOKING_ADDRESS = '/businessAddress/ops/save';
export const FETCH_PRICE_DATA = '/CRMDashboard/consignments/fetchPriceData';
export const FETCH_TAT_DATA = '/CRMDashboard/consignments/fetchTATData';
export const FETCH_SEVICEABILITY_DATA = '/CRMDashboard/consignments/fetchServiceabilityData';
export const PRINT_SHIPPING_LABEL = '/print/coordinator/getStream';
export const PRINT_INVOICE_LABEL = '/print/coordinator/downloadInvoice';
export const FETCH_RIDER_LIST_HUB  = '/Dashboard/WorkerManagement/worker/searchWorker';
export const FETCH_BM_DASHBOARD_DATA = '/Dashboard/branchCashTracking/fetchBmDashboardData';
export const THIRD_PARTY_CONSIGNMENT_INSCAN = '/CRMDashboard/consignments/thirdParty/inscan';
export const FETCH_RIDERS_OF_HUB_WITH_DESCENDANT = '/CRMDashboard/hub/getAllWorkersFromHubWithWIthDescendant';

//password policy
export const GET_PASSWORD_POLICY = '/Dashboard/password-policy';
export const GET_ACTIVITY_LOG = '/Dashboard/employee/session';
export const EDIT_PASSWORD_POLICY = '/Dashboard/password-policy/edit';
export const EDIT_PASSWORD_POLICY_IMAGE = '/Dashboard/password-policy/get-put-image-url';
export const EDIT_PASSWORD_CONSTRAINT = '/Dashboard/password-policy/password-constraint/edit';

export const FETCH_EMPLOYEES_BRANCH_LEAD = '/Dashboard/employeeMaster/listEmployeeMaster';
export const ENABLE_EMPLOYEE_BRANCH_LEAD = '/Dashboard/employeeMaster/updateEmployeeMaster';
//third party recon
export const GET_THIRD_PARTY_RECON_LIST = '/RetailDashboard/reconciliation/courierAccount/list';
export const GET_THIRD_PARTY_RECON_CN_LIST = '/RetailDashboard/reconciliation/courierAccount/consignmentList';
export const CREATE_THIRD_PARTY_TRANSACTION = '/CRMDashboard/courierAccountReconciliation/transaction/create';
export const DOWNLOAD_THIRD_PARTY_RECON = '/RetailDashboard/reconciliation/CourierAccount/courier_account_consignment.csv';
export const DOWNLOAD_SAMPLE_THIRD_PARTY_RECON = '/RetailDashboard/reconciliation/CourierAccount/sample_courier_account_consignment.csv';
export const DOWNLOAD_CARRIER_RECON_LIST = '/RetailDashboard/reconciliation/CourierAccount/courier_account.csv';
export const GET_CONSIGNMENT_BALANCE_LIST_THIRD_PARTY = '/RetailDashboard/reconciliation/CourierAccount/getConsignmentBalanceList';
export const DOWNLOAD_COURIER_ACCOUNT_TRANSACTION = '/CRMDashboard/courierAccountReconciliation/downloadCourierAccountBankTransaction';
export const RECONCILE_CARRIER_CN = '/RetailDashboard/reconciliation/CourierAccount/courierAccountConsignmentsReconcile';
export const DOWNLOAD_CARRIER_CN_RECON_LIST = '/RetailDashboard/reconciliation/CourierAccount/courier_account_consignments_reconcile_list';
export const SCAN_CARRIER_RECON_CN = '/RetailDashboard/reconciliation/CourierAccount/scanConsignmentForReconciliation';
export const FETCH_CARRIER_CN_FROM_DRAFT = '/RetailDashboard/reconciliation/CourierAccount/fetchCourierAccountConsignmentsFromDraft';
export const REMOVE_CARRIER_CN_DRAFT = '/RetailDashboard/reconciliation/CourierAccount/removeCourierAccountConsignmentFromDraft';
export const FETCH_CN_CASH_BALANCE = '/RetailDashboard/reconciliation/CourierAccount/fetchConsignmentsCashBalance';
export const UPLOAD_BULK_SETTLEMENT = '/Dashboard/CreditModule/settleCreditsBulk';
export const DOWNLOAD_SAMPLE_BULK_SETTLEMENT = '/Dashboard/CreditModule/sample_csv_settle_bulk_credits.csv';
export const DOWNLOAD_BULK_SETTLEMENT = '/Dashboard/riderReconciliation/tasks/download';

export const GET_HUB_DETAILS = '/Dashboard/HubManagement/hub';

//Ops Dashboard Manifest Declaration Module
export const GET_MANIFEST_REQUEST_LIST = '/Dashboard/customs/getTabadulManifestRequestList';
export const CREATE_MANIFEST_REQUESTS = '/Dashboard/customs/manifestUpload';
export const UPDATE_MANIFEST_REQUESTS = '/Dashboard/customs/updateTabadulManifestRequest';
export const BULK_APPROVE_REJECT_REQUESTS = '/Dashboard/customs/bulkUpdateTabadulManifestRequest';
export const FETCH_MANIFEST_BUCKETS_COUNT = '/Dashboard/customs/getTabadulManifestCount';
export const CHECK_SHIPMENT_EXISTS = '/Dashboard/customs/checkIfShipmentExists';
export const SEARCH_DOC_REF_NO = '/Dashboard/customs/searchTabadulManifest';
export const GET_MANIFEST_UPLOAD_S3_LINK = '/Dashboard/customs/getSignedUrl';
export const UPDATE_UPLOAD_MANIFEST = '/Dashboard/customs/updateFileUpload';
export const ARCHIVE_MANIFEST = '/Dashboard/customs/updateTabadulManifestRequest';
export const MANIFEST_ERRORS = '/Dashboard/customs/manifestUpload';

//softdata failure
export const FETCH_FAILED_SOFTDATA = '/Dashboard/externalRequest/v1/fetchData';
export const EDIT_RETRY_FAILED_SOFTDATA = '/Dashboard/externalRequest/consignments/editAndRetry';
export const DOWNLOAD_SOFTDATA_FAILURES = '/Dashboard/externalRequest/v1/downloadExternalRequestSoftdataLogs';
export const SOFTDATA_FAILURE_BUCKET_SUMMARY = '/Dashboard/externalRequest/v1/getBucketSummary';
export const DELETE_SOFTDATA_REQUESTS = '/Dashboard/externalRequest/v1/deleteExternalSoftdataRequests';

//marketplace integration
export const LINK_STORE_URL = '/Dashboard/shopify/v1/linkStoreUrl';
export const FETCH_SHOPIFY_INTEGRATION_KEY = '/Dashboard/getShopifyIntegrationKey';
export const REGENERATE_SHOPIFY_INTEGRATION_KEY = '/Dashboard/regenerateShopifyIntegrationKey';

// SMS Template Module
export const GET_MESSAGE_LIST = '/OpsDashboard/message/template/list';
export const GET_SMS_MASTER_DATA = '/OpsDashboard/message/template/masterdata';
export const GET_SMS_TAG_PLACEHOLDERS = '/OpsDashboard/message/template/placeholders/get';
export const ADD_SMS_TEMPLATE = '/OpsDashboard/message/template/add';
export const DELETE_SMS_TEMPLATE = '/OpsDashboard/message/template/delete';
export const CHANGE_STATUS_TEMPLATE = '/OpsDashboard/message/template/update-status';
export const FETCH_TEMPLATE = '/OpsDashboard/message/template/getById';
export const EDIT_SMS_TEMPLATE = '/OpsDashboard/message/template/edit';

// Validation Management Module
export const GET_ALL_VALIDATION_LIST = '/Dashboard/validation/fetchAll';
export const GET_REQUIRED_DATA = '/Dashboard/validation/getRequiredData';
export const ADD_VALIDATION = '/Dashboard/validation/create';
export const DELETE_VALIDATION = '/Dashboard/validation/delete';
export const EDIT_VALIDATION = '/Dashboard/validation/edit';
export const PUBLISH_VALIDATION = '/Dashboard/validation/publish';
export const RUN_SIMULATION = '/Dashboard/validation/simulate';
export const GET_GME_MASTER_DATA = '/Dashboard/validation/getGmeMasterData';

// Courier Fields Master
export const GET_COURIER_FIELDS = '/ltl/courierAllocationFieldMaster/fetch';
export const ADD_COURIER_FIELDS = '/ltl/courierAllocationFieldMaster/addSingle';
export const DELETE_COURIER_FIELDS = '/ltl/courierAllocationFieldMaster/deleteSingle';
export const GET_ALL_PARTNERS_COURIER_FIELDS = '/ltl/courierAllocationFieldMaster/getAllCourierPartner';
export const GET_WITH_FILTER_COURIER_FIELDS = '/ltl/courierAllocationFieldMaster/fetchWithFilter';
export const DEFAULT_COURIER_FIELDS = '/ltl/courierAllocationFieldMaster/fetchDefaultMapping';
export const UPDATE_COURIER_FIELDS = '/ltl/courierAllocationFieldMaster/updateSingle';
export const UPDATE_COURIER_FIELD_MASTER_CONFIG = '/Dashboard/updateCourierFieldMasterConfig';
export const GET_COURIER_FIELD_MASTER_CONFIG = '/Dashboard/getCourierFieldMasterConfig';
export const COURIER_FIELD_MASTER_FILTER_KEYS = '/ltl/courierAllocationFieldMaster/filterKeys';
export const COURIER_FIELD_MASTER_MASTER_DATA = '/ltl/courierAllocationFieldMaster/masterdata/fetch';

// carrier allocation
export const FETCH_ALLOCATION_MASTERDATA = '/courierAllocationData/masterdata/fetch';
export const DELETE_ALLOCATION_CONFIG = '/courierAllocationData/removeConfig';
export const REORDER_ALLOCATION_CONFIG = '/courierAllocationData/reorderConfigs';
export const ADD_ALLOCATION_CONFIG = '/courierAllocationData/addConfigs';
export const FETCH_ALLOCATION_CONFIG_MASTERDATA = '/courierAllocationData/getConfigMasterdata';
export const FETCH_ALLOCATION_DATA = '/pricedata/getCharges';
export const UPDATE_ALLOCATION_DATA = '/courierAllocationData/addConfigMetaData';
export const FETCH_ALLOCATION_RULES = '/pricedata/get';
export const DELETE_ALLOCATION_RULES = '/pricedata/deleteMultiple';
export const ADD_ALLOCATION_RULES = '/pricedata/update';
export const FETCH_GME_DOWNLOAD_REQUESTS = '/pricedata/getDownloadRequests';
export const FETCH_UPDATE_HEADERS = '/pricedata/getUpdateHeader';
export const UPDATE_RESCHEDULED_REASON = '/CRMDashboard/consignments/updateRescheduleReason';
export const DOWNLOAD_ALLOCATION_DATA = '/pricedata/v2/download';
export const DOWNLOAD_ALLOCATION_DATA_SYNC = '/pricedata/download';
export const DISABLE_ALLOCATION_RULES = '/pricedata/disablePriceDataMultiple';
export const EDIT_ALLOCATION_RULE = '/pricedata/editPriceData';
export const DISTINCT_PRICE_DATA_FOR_ALLOCATION = '/pricedata/getFieldDistinctData';

// code free setup label
export const FETCH_LABEL_LIST = '/reactPdfLabel/fetch/list';
export const FETCH_LABEL_SINGLE = '/reactPdfLabel/fetchOne';
export const RENDER_LABEL_SINGLE = '/reactPdfLabel/render';
export const SAVE_LABEL_DRAFT = '/reactPdfLabel/saveDraft';
export const PUBLISH_LABEL = '/reactPdfLabel/publish';
export const FETCH_SAMPLE_CN_DATA = '/reactPdfLabel/fetchSampleConsignment';
export const DELETE_DRAFT = '/reactPdfLabel/deleteDraft';
export const FETCH_PUBLISHED_LABEL_LIST = '/CRMDashboard/getAllPublishLabels';
export const UNPUBLISH_LABEL = '/reactPdfLabel/unPublishLabel';

// Service Task Master
export const CREATE_SERVICE_TASK = '/Dashboard/servicetaskmappingmanagement/create';
export const FETCH_ALL_SERVICE_TASK = '/Dashboard/servicetaskmappingmanagement/get';
export const FETCH_ONE_SERVICE_TASK = '/Dashboard/servicetaskmappingmanagement';
export const UPDATE_SERVICE_TASK = '/Dashboard/servicetaskmappingmanagement';

//Carrier Tracking revamp
export const COURIER_TRACKING_FETCH = '/ltl/couriertracking/fetchCourierTrackingMappingData';
export const COURIER_TRACKING_ADD = '/ltl/couriertracking/addCourierTrackingMappingData';
export const COURIER_TRACKING_DELETE = '/ltl/couriertracking/deleteCourierTrackingMappingData';
export const COURIER_TRACKING_UPDATE = '/ltl/couriertracking/editCourierTrackingMappingData';
export const COURIER_TRACKING_ADD_MAPPING_GROUP = '/ltl/couriertracking/group/add';
export const COURIER_TRACKING_EDIT_MAPPING_GROUP = '/ltl/couriertracking/group/edit';
export const COURIER_TRACKING_DELETE_MAPPING_GROUP = '/ltl/couriertracking/group/delete';
export const COURIER_TRACKING_UPDATE_SETTINGS = '/ltl/couriertracking/settings/update';

//Reason Category Master
export const CREATE_REASON = '/Dashboard/ConfigurationSetup/reasonMaster/create';
export const CREATE_REASON_MULTIPLE = '/Dashboard/ConfigurationSetup/reasonMaster/createMultiple';
export const FETCH_ALL_REASON = '/Dashboard/ConfigurationSetup/reasonMaster';
export const UPDATE_REASON = '/Dashboard/ConfigurationSetup/reasonMaster';

export const COURIER_TRACKING_ADD_BULK = '/ltl/couriertracking/addCourierTrackingMappingDataInBulk';
//Packaging Type Master
export const CREATE_PACKAGING_TYPE = '/Dashboard/ConfigurationSetup/packagingType/create';
export const FETCH_ALL_PACKAGING_TYPE = '/Dashboard/ConfigurationSetup/packagingType';
export const UPDATE_PACKAGING_TYPE = '/Dashboard/ConfigurationSetup/packagingType';

//Partial Delivery Reasons
export const CREATE_PARTIAL_DELIVERY_REASON = '/Dashboard/ConfigurationSetup/partialDeliveryReason/create';
export const FETCH_ALL_PARTIAL_DELIVERY_REASON = '/Dashboard/ConfigurationSetup/partialDeliveryReason';
export const UPDATE_PARTIAL_DELIVERY_REASON = '/Dashboard/ConfigurationSetup/partialDeliveryReason';

//Late Delivery Reasons
export const CREATE_LATE_DELIVERY_REASON = '/Dashboard/ConfigurationSetup/lateDeliveryReason/create';
export const FETCH_ALL_LATE_DELIVERY_REASON = '/Dashboard/ConfigurationSetup/lateDeliveryReason';
export const UPDATE_LATE_DELIVERY_REASON = '/Dashboard/ConfigurationSetup/lateDeliveryReason';

//Generic Reason Master API's
export const UPDATE_REASON_MASTER = (reasonId) => `/Dashboard/ConfigurationSetup/reasonMaster/${reasonId}/update`;
// Push Notifications
export const GET_PUSH_NOTIFICATIONS = '/Dashboard/push-notification';
export const CHANGE_STATUS_PUSH_NOTIFICATION = '/Dashboard/push-notification/changeStatus';
export const GET_AUDIENCE_LIST = '/pushNotification/utility/get';
export const ADD_PUSH_NOTIFICATION = '/Dashboard/push-notification/create';
export const EDIT_PUSH_NOTIFICATION = '/Dashboard/push-notification/update';
export const GET_S3_URLS = '/Dashboard/push-notification/get-s3-urls';

//Allocation Engine

export const GET_META_DATA_FOR_ALLOCATION_ENGINE = '/courierAllocationEngine/getMetaData';
export const GET_TREE_FOR_ALLOCATION_ENGINE = '/courierAllocationEngine/getTree';
export const POST_TREE_FOR_ALLOCATION_ENGINE = '/courierAllocationEngine/postTree';

// Two Factor Authentication
export const CHANGE_TWO_FACTOR_DATA = '/Dashboard/orgConfigTwofactor';

export const TMS_DOWNLOAD_SAMPLE_EWB_THRESHOLD_MASTER =
  '/TMSDashboard/setup/ewbThresholdMaster/sample_csv_ewb_threshold_master.csv';

export const MM_TRIP_CN_WITH_EWB_STATUS = '/TMSDashboard/ist/v1/consignmentsWithEWBStatus';
export const MM_TRIP_CN_WITH_MISSING_EWB = '/TMSDashboard/ist/v1/checkEWBStatus';
export const FLM_TRIP_CN_WITH_EWB_STATUS = '/retaildashboard/tripmanager/consignmentsWithEWBStatus';
export const GENERATE_EWB_FLM_TRIP_CN = '/retaildashboard/trip/v1/generateConsignmentEWB';
export const GENERATE_EWB_MM_TRIP_CN = '/TMSDashboard/ist/v1/generateConsignmentEWB';
export const DOWNLOAD_IST_VERIFICATION_DETAILS = '/TMSDashboard/ist/v1/downloadVerificationDetails';

// Price Calculator -
export const FETCH_CONSIGNMENT_PRICE_CALCULATOR_DATA = '/CRMDashboard/consignment/price/v1/fetch';
export const DOWNLOAD_CONSIGNMENT_PRICE_CALCULATOR_DATA = '/CRMDashboard/consignment/price/v1/download';
export const DOWNLOAD_SAMPLE_CSV_FOR_CHECK_CONSIGNMENT_PRICE = '/CRMDashboard/consignment/price/v1/sample_csv_calculate_price_master.csv';
export const CHECK_CONSIGNMENT_PRICE = '/CRMDashboard/consignment/price/v1/calculate';
// Rate Masters
export const FETCH_TRIP_CHARGES = '/pricedata/getCharges';
export const FETCH_TRIP_SAMPLE_UPLOAD_EXCEL = '/pricedata/getCSV';
export const FETCH_TRIP_CHARGES_DATA = '/pricedata/get/v2';
export const UPDATE_TRIP_CHARGES = '/pricedata/update';
export const DELETE_SINGLE_TRIP_CHARGE = '/pricedata/delete';
export const FETCH_ALLOCATION_ENGINE_PLAYGROUND_METADATA = '/pricedata/fetchAllocationEnginePlaygroundMetadata';
export const GET_PREDEFINED_CHARGES_OR_ATTRIBUTES = '/pricedata/getPredefinedChargesOrAttributes';
export const VALIDATE_AND_UPDATE_ALLOCATION_ENGINE_METADATA = '/pricedata/validateAndUpdateAllocationEngineMetadata';

// App User
export const FETCH_APP_USER_REGISTRATION = '/CRMDashboard/request/get';
export const APPROVE_APP_USER = '/CRMDashboard/request/approve';
export const REJECT_APP_USER = '/CRMDashboard/request/reject';
// Currency Conversion
export const CREATE_NEW_CURRENCY_CONVERSION = '/CRMDashboard/conversion/create';
export const FETCH_ALL_CURRENCY_CONVERSION = '/CRMDashboard/conversion/get';
export const UPDATE_CURRENCY_CONVERSION = '/CRMDashboard/conversion/update';
export const DOWNLOAD_SAMPLE_CSV_CURRENCY_CONVERSION = '/CRMDashboard/conversion/downloadCsv';
export const DELETE_SINGLE_CURRENCY_CONVERSION = '/CRMDashboard/conversion/delete';
export const BULK_UPLOAD_CURRENCY_CONVERSION = '/CRMDashboard/conversion/bulkCreate';

// Address Register Master
export const CREATE_ADDRESS_REGISTER = '/CRMDashboard/AddressManagement/address/create';
export const FETCH_ALL_ADDRESS_REGISTER = '/CRMDashboard/AddressManagement/addressList';
export const BASE_URL_ADDRESS_REGISTER = '/CRMDashboard/AddressManagement/address';
export const FETCH_ADDRESS_REGISTER_DETAILS = '/details';
export const UPDATE_ADDRESS_REGISTER = '/update';
export const SAMPLE_CSV_ADDRESS_REGISTER_CREATE = '/CRMDashboard/AddressManagement/address/sampleCsv';
export const SAMPLE_CSV_ADDRESS_REGISTER_UPDATE = '/CRMDashboard/AddressManagement/address/update/sampleCsv';
export const BULK_CREATE_ADDRESS_REGISTER = '/CRMDashboard/AddressManagement/address/csvUpload';
export const BULK_UPDATE_ADDRESS_REGISTER = '/CRMDashboard/AddressManagement/address/update/csvUpload';
export const DOWNLOAD_ADDRESS_REGISTER = '/CRMDashboard/AddressManagement/address/csv';
export const FETCH_ADDRESS_NODE_CONFIG = '/CRMDashboard/addressManagement/getAddressNodeConfig';
export const FETCH_ADDRESS_NODES = '/CRMDashboard/addressManagement/getAddressNodeData';
export const FETCH_LOCALITY_FROM_ADDRESS_MAPPING = '/CRMDashboard/addressManagement/fetchAddressCode';

// Integration Marketplace
export const INTEGRATION_MARKETPLACE_FETCH_MASTER_DATA = '/IntegrationMarketplaces/masterdata';
export const INTEGRATION_MARKETPLACE = '/IntegrationMarketplaces';
export const RETRY_BOOKING = '/CRMDashboard/consignments/retryBooking';
export const CANCEL_BOOKING = '/CRMDashboard/consignments/cancelBooking';

export const GET_PAYMENT_CONFIGURATIONS = '/FoodDashboard/fetchPaymentConfigurations';
export const ADD_PAYMENT_CONFIGURATIONS = '/FoodDashboard/addPaymentConfigurations';
export const DELETE_PAYMENT_CONFIGURATION = '/FoodDashboard/deletePaymentConfiguration';
export const UPDATE_PAYMENT_CONFIGURATION = '/FoodDashboard/updatePaymentConfiguration';

// ndr
export const MARK_RTO_NDR = '/CRMDashboard/consignments/thirdParty/markConsignmentAsRTO';
export const MARK_RETRY_NDR = '/CRMDashboard/consignments/thirdParty/reattemptConsignment';
export const RESCHEDULE_NDR = '/CRMDashboard/consignments/thirdParty/rescheduleConsignment';
export const EDIT_CN_NDR = '/CRMDashboard/consignments/thirdParty/editConsignment';
export const GET_HUB_DATA_FROM_PINCODE = '/Dashboard/allowedmanifestpincodemastermanagement/get';

export const TRIGGER_OUT_BOUND = '/CRMDashboard/consignment/triggerOutboundEvent';

//email template
export const GET_EMAIL_TEMPLATE = '/OpsDashboard/template/fetch';
export const CREATE_EMAIL_TEMPLATE = '/OpsDashboard/template/add';
export const EDIT_EMAIL_TEMPLATE = '/OpsDashboard/template/edit';

// Sorting Module
export const GET_SORTING_METADATA = '/Dashboard/setup/sorting/v1/fetch';
export const UPDATE_SORTING_METADATA = '/Dashboard/setup/sorting/v1/update';

// Single Sign On
export const FETCH_ALL_REGISTERED_SSO = '/Dashboard/sso/v1/get';
export const ADD_NEW_SSO = '/Dashboard/sso/v1/add';
export const EDIT_SSO = '/Dashboard/sso/v1/edit';
export const DELETE_SSO = '/Dashboard/sso/v1/delete';

// Vsibility configuration
export const GET_VISIBILITY_CONFIGURATION_DATA = '/Dashboard/setup/visibilityConfig/v1/fetch';
export const UPDATE_VISIBILITY_CONFIGURATION_DATA = '/Dashboard/setup/visibilityConfig/v1/update';

// API Key Management Console
export const FETCH_API_KEYS = '/Dashboard/apikey';
export const EXPIRE_API_KEY = '/Dashboard/apikey/expire';
export const CREATE_API_KEY = '/Dashboard/apikey/create';

// Revoke function from support tools to dashboard
export const REVOKE_CANCELLATION_CN_DETAILS = '/Dashboard/revoke/cancellation';
export const REVOKE_DELIVERY_CN_DETAILS = '/Dashboard/revoke/delivered';
//Customer Management
export const FETCH_ALL_CUSTOMERS = '/Dashboard/CustomerManagement/customerList';
export const GET_CUSTOMER_DETAIL = '/Dashboard/CustomerManagement/getCustomer';
export const CREATE_CUSTOMER = '/Dashboard/CustomerManagement/createCustomer';
export const UPDATE_CUSTOMER = '/Dashboard/CustomerManagement/updateCustomer';
export const DOWNLOAD_CUSTOMERS = '/Dashboard/CustomerManagement/csv/customers.csv';
export const BULK_CREATE_CUSTOMER_CSV = '/Dashboard/CustomerManagement/create/csvupload';
export const BULK_UPDATE_CUSTOMER_CSV = '/Dashboard/CustomerManagement/updateCustomerExcelUpload';
export const DOWNLOAD_CSV_FOR_CUSTOMER = '/Dashboard/CustomerManagement/customer/sample_csv_create_customer';
export const SEARCH_CUSTOMER = '/Dashboard/CustomerManagement/searchCustomers';
export const GET_CUSTOMER_TIMEZONES = '/Dashboard/CustomerManagement/customer/getTimezoneListForCustomer';
export const FETCH_HIERARCHY_DATA = '/Dashboard/addressManagement/getHierarchyData';

//Virtual Series
export const GET_VIRTUAL_SERIES = '/series/ops/getSeries';
export const ADD_VIRTUAL_SERIES = '/series/ops/addSeries';
export const DELETE_VIRTUAL_SERIES = '/dashboard/CustomerManagement/series/delete';
export const DELETE_VIRTUAL_SERIES_BULK = '/dashboard/CustomerManagement/series/deleteBulk';
export const DOWNLOAD_VIRTUAL_SERIES_REQUEST = '/series/ops/createDownloadRequest';
export const GET_SOFTDATA_API_KEY = '/Dashboard/getSoftdataApiKey';
export const REGENERATE_SOFTDATA_API_KEY = '/Dashboard/regenerateSoftdataApiKey';
export const SEND_PASSWORD_RESET_MAIL = '/Dashboard/CustomerManagement/sendResetPasswordMail';
export const UPDATE_WEBHOOK_DETAILS = '/Dashboard/CustomerManagement/updateWebhookConfig';

// Object View
export const FETCH_ALL_OBJECT_VIEWS = '/Dashboard/objectview/get/all';
export const FETCH_OBJECT_VIEW_BY_ID = (viewId) => `/Dashboard/objectview/${viewId}/get`;
export const SEARCH_OBJECT_VIEWS = '/Dashboard/objectview/search';
export const CREATE_OBJECT_VIEW = '/Dashboard/objectview/create';
export const UPDATE_OBJECT_VIEW = (viewId) => `/Dashboard/objectview/${viewId}/update`;
export const DELETE_OBJECT_VIEW = (viewId) => `/Dashboard/objectview/${viewId}/delete`;
export const ACTION_OBJECT_VIEW = (viewId) => `/Dashboard/objectview/${viewId}/action`;
export const MARK_VIEW_AS_DEFAULT = '/Dashboard/objectview/markDefault';
export const FETCH_OBJECT_VIEW_MASTER_DATA = '/Dashboard/objectview/masterdata/fetch';
export const FETCH_OBJECT_VIEW_DATA_OBJECTS = '/Dashboard/objectview/data/fetch';
export const DOWNLOAD_OBJECT_VIEW_DATA_OBJECTS = '/Dashboard/objectview/data/download';
export const GET_DEFAULT_OBJECT_VIEW = '/Dashboard/objectview/default/get';

// Object Page
export const FETCH_ALL_OBJECT_PAGES = '/Dashboard/objectpage/get/all';
export const CREATE_OBJECT_PAGE = '/Dashboard/objectpage/create';
export const UPDATE_OBJECT_PAGE = (viewId) => `/Dashboard/objectpage/${viewId}/update`;
export const ACTION_OBJECT_PAGE = (viewId) => `/Dashboard/objectpage/${viewId}/action`;
export const FETCH_OBJECT_PAGE_MASTER_DATA = '/Dashboard/objectpage/masterdata/fetch';
export const FETCH_OBJECT_PAGES_FOR_EMPLOYEE = '/Dashboard/objectpage/employee';

export const FETCH_EMPLOYEE_ROLES = '/Dashboard/Permission/getUserRoles';

// Get object data from view
export const FETCH_VIEW_OBJECT_DATA = '/Dashboard/view/data/fetch';

// Workflow Builder
export const GET_WORFLOW_BUILDER_METADATA = '/Dashboard/objectmetadata/get';
export const SET_WORFLOW_BUILDER_METADATA = '/Dashboard/objectmetadata/update';
export const GENERATE_OBJECT_BUILDER_EXCEL = '/Dashboard/objectmetadata/generatesampleexcel';

/**
 * Vendor Payout API's
 */
export const SEARCH_VEHICLE_VENDOR = '/Dashboard/vehiclevendor/get';
export const FETCH_VENDOR_PAYOUT_LOGS = '/Dashboard/vendor-payout/v1/fetch';
export const DOWNLOAD_VENDOR_PAYOUT_LOGS = '/Dashboard/vendor-payout/v1/download';
export const CALCULATE_VENDOR_PAYOUT = '/Dashboard/vendor-payout/v1/calculate';

// Supplementary Services
export const FETCH_SUPPLEMENTARY_SERVICE_MASTER = '/Dashboard/supplementary-service-master/v1/fetch';
export const DOWNLOAD_SUPPLEMENTARY_SERVICE_MASTER = '/Dashboard/supplementary-service-master/v1/download';
export const CREATE_SUPPLEMENTARY_SERVICE_MASTER = '/Dashboard/supplementary-service-master/v1/create';
export const DELETE_SUPPLEMENTARY_SERVICE_MASTER_SINGLE = '/Dashboard/supplementary-service-master/v1/deleteSingle';
export const UPDATE_SUPPLEMENTARY_SERVICE_MASTER = '/Dashboard/supplementary-service-master/v1/update';

// transformation
export const GET_DATA_FOR_TRANSFORMATION = '/Dashboard/transformation/get/getDataForTransformation';
export const GET_ORGANISATIONSETTINGS_FIELDS = '/Dashboard/transformation/get';
export const REORDER_TRANSFORMATION = '/Dashboard/transformation/reorder';
export const CREATE_TRANSFORMATION = '/Dashboard/transformation/create';
export const EDIT_TRANSFORMATION = '/Dashboard/transformation/edit';
export const DELETE_TRANSFORMATION = '/Dashboard/transformation/delete';
export const TRANSFORMATION_SIMULATION = '/Dashboard/transformation/simulate';

// pricing master contracts
export const GET_PRICING_CONTRACTS = '/pricedata/getContractCodes';
export const CREATE_PRICING_CONTRACTS = '/pricedata/addOrUpdateContractCodes';
export const DELETE_PRICING_CONTRACTS = '/pricedata/deleteContract';
export const BULK_UPLOAD_PRICING_CONTRACTS = '/pricedata/createBulkContract';
export const DOWNLOAD_SAMPLE_CONTRACT = '/Dashboard/contract.csv';

//App Flow Config
export const FETCH_APPFLOW_CONFIG = '/Dashboard/ConfigurationSetup/appflowconfigobject/V2';
export const FETCH_APPFLOW_OBJECT_CONFIG = '/Dashboard/ConfigurationSetup/appflowconfigobject/masterdata/V2';
export const CREATE_APPFLOW_OBJECT = '/Dashboard/ConfigurationSetup/appflowconfigobject/create/V2';
export const UPDATE_APPFLOW_OBJECT = '/Dashboard/ConfigurationSetup/appflowconfigobject/update/V2';
export const DELETE_APPFLOW_OBJECT = '/Dashboard/ConfigurationSetup/appflowconfigobject/delete/V2';
export const GET_CUSTOMER_LIST = '/Dashboard/ConfigurationSetup/appflowconfigobject/searchcustomer/V2';

export const FETCH_TRIP_COMMENTS = '/retaildashboard/fetchTripComments';
export const POST_TRIP_COMMENT = '/retaildashboard/postTripComment';
export const UPLOAD_CONSIGNMENT_DOCUMENTS = '/Dashboard/consignment/updateDocuments';

// generate devrev auth token for user
export const GENERATE_DEVREV_AUTH_TOKEN = '/Dashboard/devrev/authtoken';

// item unit master api
export const FETCH_ITEM_UNITS = '/Dashboard/itemunitmastermanagement/fetch';
export const ADD_ITEM_UNITS_MULTIPLE = '/Dashboard/itemunitmastermanagement/createmultiple';
export const UPDATE_ITEM_UNITS_MULTIPLE = '/Dashboard/itemunitmastermanagement/updatemultiple';
export const DOWNLOAD_ITEM_UNITS = '/Dashboard/itemunitmastermanagement/itemUnitMastercsv.csv';

// generic approval workflow builder
export const GET_SUMMARY_METRICS_WORKFLOW = '/CRMDashboard/request/getBucketMetricCounts';
export const GET_REQUESTS_WORKFLOW = '/CRMDashboard/request/get';
export const ACCEPT_REQUESTS_WORKFLOW = '/CRMDashboard/request/approve';
export const REJECT_REQUESTS_WORKFLOW = '/CRMDashboard/request/reject';

// organisaion config
export const FETCH_ORGANISATION_CONFIG = '/Dashboard/fetchOrganisationCourierSettings';
export const UPDATE_ORGANISATION_CONFIG = '/Dashboard/updateOrganisationCourierSettings';

export const UPDATE_GEOLOCATION = '/retaildashboard/consignments/updateGeoLocation';
// generic approval workflow builder - approval rules
export const GET_APPROVAL_RULES_MASTER_DATA = '/Dashboard/approvalRule/getMasterData';
export const GET_APPROVAL_RULES = '/Dashboard/approvalRule/fetchAll';
export const ADD_APPROVAL_RULE = '/Dashboard/approvalRule/create';
export const EDIT_APPROVAL_RULE = '/Dashboard/approvalRule/edit';
export const DELETE_APPROVAL_RULE = '/Dashboard/approvalRule/delete';
export const SIMULATE_APPROVAL_RULES = '/Dashboard/approvalRule/simulate';
export const PUBLISH_APPROVAL_RULE = '/Dashboard/approvalRule/publish';

//get employee names and roles
export const GET_EMPLOYEE_IDS = '/Dashboard/Permission/searchEmployeeSuperAdmin';
export const GET_EMPLOYEE_ROLES = '/Dashboard/Permission/getUserRoles';

// Courier handover manifest
export const GET_COURIER_HANDOVER_MANIFESTS = '/CRMDashboard/consignment/manifest';

// landing page dashboard
// to be changed
export const GET_DASHBOARD_GROUP_ID = '/CRMDashboard/landingPage/dashboardId';
export const GET_DASHBOARD_GROUP = '/CRMDashboard/dashboardGroup';
export const GET_DASHBOARD_REPORTS_RELOADING_DATA = '/CRMDashboard/dashboardGroup/reportsData';
export const GET_REPORT_VISALISATION = '/CRMDashboard/report/getReportVisualisation';
export const GET_RTO_REASON = '/Dashboard/ConfigurationSetup/getRtoMiscReasons';
export const GET_OTP_FOR_CONSIGNMENT = '/Dashboard/consignment/getCurrentTaskOTP';

// Drivers trip data
export const GET_RIDER_TRIP_DATA = '/retaildashboard/workers/trips';
export const GET_RIDER_SHIFT_DATA = '/Dashboard/getShiftDetails'

// get signed url
export const GET_SIGNED_URL = '/CRMDashboard/cloud-storage/generate-signed-read-url';